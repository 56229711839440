import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { Observable } from "rxjs";
import { IAuditObservationEvaluationStatus } from "../../interfaces/qa-management/auditObservationEvaluationStatus";
import { IAuditObservationDetail } from "../../interfaces/qa-management/auditObservationDetailData";
const apiUrl = environment.apiUrl + '/audit-observation';

@Injectable({
    providedIn: "root",
})
export class AuditObservationService {
    constructor(private http: HttpClient) { }

    public getAllEvaluationStatus(): Observable<IAuditObservationEvaluationStatus[]> {
        return this.http.get<IAuditObservationEvaluationStatus[]>(`${apiUrl}/evaluation-status/all`);
    }

    public getAllDetails(filters: any): Observable<IAuditObservationDetail[]> {
        let params = new HttpParams();
        Object.keys(filters).forEach(key => {
            if (filters[key] != null && filters[key] != undefined) {
                params = params.append(key, filters[key]);
            }
        });
        return this.http.get<IAuditObservationDetail[]>(`${apiUrl}/details/all`, { params });
    }
}

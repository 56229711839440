import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { AdministrationLoadingDataService } from "../services/administration/administration-loading-data-service";

@Component({
  selector: "administration-component",
  templateUrl: "./administration.component.html",
})
export class AdministrationComponent {
  title = "Administration";
  links: any[] = [];
  activeTab: string = "userRequests"; // Default to the first tab

  constructor(
    private http: HttpClient,
    private administrationLoadingDataService: AdministrationLoadingDataService
  ) {}

  ngOnInit(): void {}

  setActiveTab(tabName: string): void {
    this.activeTab = tabName;

    if (tabName === "userRequests")
      this.administrationLoadingDataService.loadUserRequestsData();
    else if (tabName === "pendingCommodityRequests")
      this.administrationLoadingDataService.loadPendingCommodityRequestsData();
    else if (tabName === "approvedCommodityRequests")
      this.administrationLoadingDataService.loadApprovedCommodityRequestsData();
    else if (tabName === "rejectedCommodityRequests")
      this.administrationLoadingDataService.loadRejectedCommodityRequestsData();
  }
}

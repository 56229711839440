// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .dng-button {
        background-color: #97002e;
        border-color: #97002e;
      }
      .small-icon {
        font-size: 20px;
      }
    `, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/shared/action-button/action-button.component.ts"],"names":[],"mappings":";MACM;QACE,yBAAyB;QACzB,qBAAqB;MACvB;MACA;QACE,eAAe;MACjB","sourcesContent":["\n      .dng-button {\n        background-color: #97002e;\n        border-color: #97002e;\n      }\n      .small-icon {\n        font-size: 20px;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

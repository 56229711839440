import { Component, Input, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { environment } from "projects/aqua3/src/environments/environment";
import { ICommodityRequestData } from "projects/aqua3/src/app/interfaces/administration/commodityRequestData";
import { CommodityRequestsDataService } from "projects/aqua3/src/app/services/administration/commodity-requests.service";
import { IVendorCommodityViewData } from "projects/aqua3/src/app/interfaces/administration/vendorCommodityViewData";
import { IUserData } from "projects/aqua3/src/app/interfaces/administration/userData";
import { UserRequestsDataService } from "projects/aqua3/src/app/services/administration/user-requests.service";
import { Observable, forkJoin, of, switchMap } from "rxjs";
import { CapitalProjectsDataService } from "projects/aqua3/src/app/services/capital-projects.service";
import { ICapitalProjectData } from "projects/aqua3/src/app/interfaces/administration/capitalProjectData";
import { IVendorCommodityData } from "projects/aqua3/src/app/interfaces/administration/vendorCommodityData";

@Component({
  selector: "perform-action-commodity-request-form.component",
  templateUrl: "./perform-action-commodity-request-form.component.html",
})
export class PerformActionCommodityRequestFormComponent implements OnInit {
  commodityRequest: ICommodityRequestData;
  vendorCommodityViewData: IVendorCommodityViewData;
  userData: IUserData;
  capitalProjectData: ICapitalProjectData;
  vendorCommodityData: IVendorCommodityData;
  actionForm: FormGroup = this.fb.group({});
  validationMessages = [];

  public subjectMatterExpert: string = "";
  public requestor: string = "";
  public commodityCode: string = "";
  public supplier: string = "";
  public manufacturer: string = "";
  public brand: string = "";
  public endorser: string = "";
  public supplierImaNumber: string = "";
  public manufacturerImaNumber: string = "";
  public supplierRating: string = "No rating";
  public manufacturerRating: string = "No rating";
  public ratings: string[] = ["No rating", "1", "2", "3"];
  manufacturerRatingVisible: boolean = false;
  supplierRatingVisible: boolean = false;

  constructor(
    private commodityRequestsDataService: CommodityRequestsDataService,
    private userRequestsDataService: UserRequestsDataService,
    private capitalProjectsDataService: CapitalProjectsDataService,
    private fb: FormBuilder,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.commodityRequestsDataService.performActionOnCommodityRequestData$.subscribe(
      (data) => {
        this.commodityRequest = data;

        this.vendorCommodityData = {
          vendorCommodityId: this.commodityRequest.vendorCommodityId,
          approvedByUserId: null,
          approvedDate: null,
          rejectedByUserId: null,
          rejectedDate: null,
          supplierCapabilityRating: null,
          supplierCapabilityComments: null,
          supplierIMANumber: null,
          supplierSD: null,
          manufacturerCapabilityRating: null,
          manufacturerCapabilityComments: null,
          manufacturerIMANumber: null,
          manufacturerSD: null,
          isApproved: false,
          isActive: false,
        };

        let formGroupConfig = {
          supplierImaNumber: [
            this.vendorCommodityData.supplierIMANumber,
            Validators.required,
          ],
          manufacturerImaNumber: [
            this.vendorCommodityData.manufacturerIMANumber,
            Validators.required,
          ],
          supplierDiversity: [
            this.vendorCommodityData.supplierSD,
            Validators.required,
          ],
          manufacturerDiversity: [
            this.vendorCommodityData.manufacturerSD,
            Validators.required,
          ],
          supplierRating: [this.supplierRating, Validators.required],
          manufacturerRating: [this.manufacturerRating, Validators.required],
          supplierComments: [
            this.vendorCommodityData.supplierCapabilityComments,
            Validators.required,
          ],
          manufacturerComments: [
            this.vendorCommodityData.manufacturerCapabilityComments,
            Validators.required,
          ],
        };

        this.actionForm = this.fb.group(formGroupConfig);

        this.loadTheData();
      }
    );
  }

  loadTheData(): void {
    this.getVendorCommodityViewData()
      .pipe(
        switchMap((vendorCommodityViewData) => {
          this.vendorCommodityViewData = vendorCommodityViewData;
          const requests: [
            Observable<IUserData | null>,
            Observable<ICapitalProjectData | null>
          ] = [
            vendorCommodityViewData && vendorCommodityViewData.requestedByUserId
              ? this.getUserData(vendorCommodityViewData.requestedByUserId)
              : of(null),
            vendorCommodityViewData &&
            vendorCommodityViewData.endorsedByProjectId
              ? this.getCapitalProjectData(
                  vendorCommodityViewData.endorsedByProjectId
                )
              : of(null),
          ];

          // Execute both requests in parallel
          return forkJoin(requests);
        })
      )
      .subscribe({
        next: ([userData, capitalProjectData]: [
          IUserData | null,
          ICapitalProjectData | null
        ]) => {
          this.userData = userData;
          this.capitalProjectData = capitalProjectData;

          this.prepareTheForm();
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  getVendorCommodityViewData() {
    return this.commodityRequestsDataService.getVendorCommodityViewById(
      this.commodityRequest.vendorCommodityId
    );
  }

  getUserData(requestedByUserId: number): Observable<IUserData> {
    return this.userRequestsDataService.getUserById(requestedByUserId);
  }

  getCapitalProjectData(
    endorsedByProjectId: number
  ): Observable<ICapitalProjectData> {
    return this.capitalProjectsDataService.getCapitalProjectById(
      endorsedByProjectId
    );
  }

  prepareTheForm() {
    this.commodityCode = `${this.vendorCommodityViewData.commodityCodeTrimmed} - ${this.vendorCommodityViewData.commodityDescriptionPath}`;
    this.supplier = `${this.vendorCommodityViewData.supplierVendorName} ${this.vendorCommodityViewData.supplierStreetAddress} ${this.vendorCommodityViewData.supplierCity} ${this.vendorCommodityViewData.supplierPostalCode}`;
    this.manufacturer = `${this.vendorCommodityViewData.manufacturerVendorName} ${this.vendorCommodityViewData.manufacturerStreetAddress} ${this.vendorCommodityViewData.manufacturerCity} ${this.vendorCommodityViewData.manufacturerPostalCode}`;
    this.brand = `${this.vendorCommodityViewData.brandName}`;

    if (
      !(
        this.vendorCommodityViewData.smecai === null ||
        this.vendorCommodityViewData.smecai === ""
      )
    ) {
      this.subjectMatterExpert = `${this.vendorCommodityViewData.smelastName} ${this.vendorCommodityViewData.smefirstName} (${this.vendorCommodityViewData.smecai})`;
    }

    if (this.vendorCommodityViewData.requestedByUserId != null) {
      this.requestor = `${this.userData.lastName} ${this.userData.firstName} (${this.userData.cai})`;
    }

    if (this.vendorCommodityViewData.endorsedBySme) {
      this.endorser = "Endorsed by the Subject Matter Expert";
    } else {
      if (this.vendorCommodityViewData.endorsedByProjectId != null)
        this.endorser = `${this.capitalProjectData.longName}`;
      else this.endorser = "";
    }

    if (this.vendorCommodityViewData.supplierImanumber != null) {
      this.vendorCommodityData.supplierIMANumber =
        this.vendorCommodityViewData.supplierImanumber;
    }

    if (this.vendorCommodityViewData.manufacturerImanumber != null) {
      this.vendorCommodityData.manufacturerIMANumber =
        this.vendorCommodityViewData.manufacturerImanumber;
    }

    if (this.vendorCommodityViewData.manufacturerIsMinorityFirm != null) {
      this.vendorCommodityData.manufacturerSD =
        this.vendorCommodityViewData.manufacturerIsMinorityFirm;
    } else {
      this.vendorCommodityData.manufacturerSD = false;
    }

    if (this.vendorCommodityViewData.supplierIsMinorityFirm != null) {
      this.vendorCommodityData.supplierSD =
        this.vendorCommodityViewData.supplierIsMinorityFirm;
    } else {
      this.vendorCommodityData.supplierSD = false;
    }

    if (
      this.vendorCommodityViewData.commodityRejectedByUserId == null &&
      this.vendorCommodityViewData.commodityApprovedByUserId == null
    ) {
      this.vendorCommodityData.supplierCapabilityComments = "";
      this.vendorCommodityData.manufacturerCapabilityComments = "";
      this.supplierRating = "No rating";
      this.manufacturerRating = "No rating";
    } else {
      this.vendorCommodityData.supplierCapabilityComments =
        this.vendorCommodityViewData.supplierRatingComments;
      this.vendorCommodityData.manufacturerCapabilityComments =
        this.vendorCommodityViewData.manufacturerRatingComments;
      this.supplierRating =
        this.vendorCommodityViewData.supplierRating.toString();
      this.manufacturerRating =
        this.vendorCommodityViewData.manufacturerRating.toString();
    }

    if (this.vendorCommodityViewData.supplierVendorId != null)
      this.supplierRatingVisible = true;

    if (this.vendorCommodityViewData.manufacturerVendorId != null)
      this.manufacturerRatingVisible = true;
  }

  approveRequest(): void {
    this.performAction(true);
  }

  denyRequest(): void {
    this.performAction(false);
  }

  performAction(isApproved: boolean): void {
    let messagesValidationsList: string[] = [];
    this.validationMessages = messagesValidationsList;

    let formSupplierImaNumber: string =
      this.actionForm.get("supplierImaNumber").value;
    let formManufacturerImaNumber: string = this.actionForm.get(
      "manufacturerImaNumber"
    ).value;
    let formSupplierDiversity: boolean =
      this.actionForm.get("supplierDiversity").value;
    let formManufacturerDiversity: boolean = this.actionForm.get(
      "manufacturerDiversity"
    ).value;
    let formSupplierRating: string =
      this.actionForm.get("supplierRating").value;
    let formManufacturerRating: string =
      this.actionForm.get("manufacturerRating").value;
    let formSupplierComments: string =
      this.actionForm.get("supplierComments").value;
    let formManufacturerComments: string = this.actionForm.get(
      "manufacturerComments"
    ).value;

    this.vendorCommodityData.supplierIMANumber = formSupplierImaNumber;
    this.vendorCommodityData.manufacturerIMANumber = formManufacturerImaNumber;
    this.vendorCommodityData.supplierSD = formSupplierDiversity;
    this.vendorCommodityData.manufacturerSD = formManufacturerDiversity;

    if (formSupplierRating === "No rating") {
      this.vendorCommodityData.supplierCapabilityRating = null;
    } else {
      this.vendorCommodityData.supplierCapabilityRating =
        parseInt(formSupplierRating);
    }

    if (formManufacturerRating === "No rating") {
      this.vendorCommodityData.manufacturerCapabilityRating = null;
    } else {
      this.vendorCommodityData.manufacturerCapabilityRating = parseInt(
        formManufacturerRating
      );
    }

    this.vendorCommodityData.supplierCapabilityComments = formSupplierComments;
    this.vendorCommodityData.manufacturerCapabilityComments =
      formManufacturerComments;

    this.commodityRequestsDataService
      .performAction(this.vendorCommodityData, isApproved)
      .subscribe({
        next: () => {
          this.router.navigate(["/administration/adminHome"]);
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  back(): void {
    this.router.navigate(["/administration/adminHome"]);
  }
}

import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

@Component({
  selector: 'faq-component',
  templateUrl: './faq.component.html'
})
export class FAQComponent {
  links: any[] = [];

  constructor(private http: HttpClient) { }

}

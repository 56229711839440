import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { FormGroup } from "@angular/forms";
import { environment } from "projects/aqua3/src/environments/environment";
import { ILookupData } from "../../interfaces/administration/lookupData";
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class PurchaserManagementService {
  private purchaserDataSource = new BehaviorSubject<ILookupData | null>(null);
  currentPurchaserData = this.purchaserDataSource.asObservable();

  constructor(private http: HttpClient) {}

  changePurchaserData(purchaserData: ILookupData) {
    this.purchaserDataSource.next(purchaserData);
  }

  public getPurchaserData(): Observable<ILookupData> {
    return this.purchaserDataSource.asObservable();
  }

  public getPurchasers(): Observable<ILookupData[]> {
    return this.http.get<ILookupData[]>(
      `${apiUrl}/purchasermanagement?pagesize=10000`
    );
  }

  public getPurchaserById(id: string): Observable<ILookupData> {
    return this.http.get<ILookupData>(`${apiUrl}/purchasermanagement/${id}`);
  }

  public savePurchaser(purchaserData: ILookupData): Observable<any> {
    return this.http.post<ILookupData>(
      `${apiUrl}/purchasermanagement/create`,
      purchaserData
    );
  }

  public updatePurchaser(
    id: string,
    purchaserData: ILookupData
  ): Observable<ILookupData> {
    return this.http.put<ILookupData>(
      `${apiUrl}/purchasermanagement/${id}/update`,
      purchaserData
    );
  }

  public deletePurchaser(id: string): Observable<any> {
    let result = this.http.delete(`${apiUrl}/purchasermanagement/${id}`);
    console.log(result);
    return result;
  }

  public validationResult(form: FormGroup) {
    let formOpcoName: string = form.get("purchaserName").value;
    let formOpcoDesc: string = form.get("purchaserInfo").value;
    let messagesValidationsList: string[] = [];

    if (formOpcoName === "")
      messagesValidationsList.push("Please enter Purchaser Name.");

    if (formOpcoDesc === "")
      messagesValidationsList.push("Please enter Purchaser Information.");

    return messagesValidationsList;
  }
}

import { Component, OnInit } from "@angular/core";
import { ILookupData } from "../../interfaces/administration/lookupData";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PurchaserManagementService } from "../../services/administration/purchaser-management.service";

//CreatePurchaserManagementComponent
@Component({
  selector: "app-create-purchaser-management",
  templateUrl: "./create-purchaser-management.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `,
  ],
})
export class CreatePurchaserManagementComponent implements OnInit {
  public title = "Create new purchaser management";
  public purchaserData: ILookupData;
  public isLoading = false;
  public form: FormGroup;
  public validationMessages = [];

  constructor(
    private service: PurchaserManagementService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.purchaserData = {
      lookupId: 0,
      name: "",
      description: "",
      groupName: "EngineeringProcurmentContractor",
      groupOrder: 0,
      parentLookupId: 0,
      isActive: true,
    };
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      purchaserName: ["", Validators.required],
      purchaserInfo: ["", Validators.required],
    });
  }

  onSubmit() {
    this.isLoading = true;

    this.validationMessages = this.service.validationResult(this.form);

    if (this.form.valid && this.validationMessages.length === 0) {
      this.purchaserData.name = this.form.get("purchaserName").value;
      this.purchaserData.description = this.form.get("purchaserInfo").value;

      this.service.savePurchaser(this.purchaserData).subscribe((data) => {
        if (data) {
          this.router.navigate(["/administration/purchaser-management"]);
        }
      });
    }
    this.isLoading = false;
  }

  cancelRequest() {
    this.router.navigate(["/administration/purchaser-management"]);
  }
}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CountriesAndStatesService } from "../../../services/countries-states.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ILookupData } from "../../../interfaces/administration/lookupData";

@Component({
  selector: "app-edit-state",
  templateUrl: "./edit-state.component.html",
})
export class EditStateComponent implements OnInit {
  public stateData: ILookupData;
  private countriesData: ILookupData[];

  constructor(
    private router: Router,
    private countriesAndStatesService: CountriesAndStatesService,
    private fb: FormBuilder,
  ) { }

  editForm: FormGroup;
  validationMessages = [];

  ngOnInit() {
    this.countriesAndStatesService.getAllCountries().subscribe(
      (countries) => {
        this.countriesData = countries;
      }
    );

    this.countriesAndStatesService.currentStateData$.subscribe(data => {
      this.stateData = data;
      this.editForm = this.fb.group({
        country: [this.stateData.parentLookupId],
        stateName: [this.stateData.name, Validators.required],
        isActive: [this.stateData.isActive],
      });
    });
  }

  submitForm(): void {
    let messagesValidationsList: string[] = [];
    let formStateName: string = this.editForm.get('stateName').value;
    let formStateIsActive: boolean = this.editForm.get('isActive').value;
    let formStateParentLookupId: number = this.editForm.get('country').value;

    if (formStateName === "")
      messagesValidationsList.push('Please enter the name of a State.');

    this.validationMessages = messagesValidationsList;

    if (this.editForm.valid && this.validationMessages.length === 0) {
      this.stateData.name = formStateName;
      this.stateData.isActive = formStateIsActive;
      this.stateData.parentLookupId = formStateParentLookupId;

      this.countriesAndStatesService.updateState(this.stateData.lookupId.toString(), this.stateData).subscribe((data) => {
        if (data) {
          this.backToCountriesAndStates();
        }
      });
    }
  }

  cancel(): void {
    this.backToCountriesAndStates();
  }

  private backToCountriesAndStates() {
    var currentCountry = this.countriesData.find(c => c.lookupId == this.stateData.parentLookupId);
    this.router.navigate(["administration/countries-states"], { state: { currentCountry: currentCountry } });
  }
}

import { Component, Input } from "@angular/core";

@Component({
  selector: "request-button-component",
  templateUrl: "./request-button.component.html",
})
export class RequestButtonComponent {
  @Input() element: any;
  actionFrom: () => void;

  buttonOnClick() {
    this.actionFrom ? this.actionFrom() : {};
  }
}

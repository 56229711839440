import { NgModule } from "@angular/core";
import { MenuModule } from "../menu/menu.module";
import { AgenciesAndInspectorsComponent } from "./agencies-and-inspectors/agencies-and-inspectors-component";
import { AgenciesComponent } from "./agencies-and-inspectors/agencies/agencies-component";
import { BuyersEngineersQaesComponent } from "./buyers-engineers-qaes/buyers-engineers-qaes-component";
import { BuyersEngineersQaesCreateComponent } from "./buyers-engineers-qaes/buyers-engineers-qaes-create-component";
import { BuyersEngineersQaesEditComponent } from "./buyers-engineers-qaes/buyers-engineers-qaes-edit-component";
import { EditInspectorFormComponent } from "./agencies-and-inspectors/inspectors/edit-inspector-form.component";
import { InspectorsComponent } from "./agencies-and-inspectors/inspectors/inspectors-component";
import { AddInspectorFormComponent } from "./agencies-and-inspectors/inspectors/add-inspector-form.component";
import { EditAgencyFormComponent } from "./agencies-and-inspectors/agencies/edit-agency-form.component";
import { AddAgencyFormComponent } from "./agencies-and-inspectors/agencies/add-agency-form.component";
import { WorkRequestsComponent } from "./work-requests/work-requests-component";
import { ManufacturerAuditAndObservationComponent } from "./manufacturer-audit-and-observation/manufacturer-audit-and-observation.component";
import { ManufacturerAuditAndObservationTableComponent } from "./manufacturer-audit-and-observation/manufacturer-audit-and-observation-table-result/manufacturer-audit-and-observation-table-result.component";

const componentList = [
  AgenciesAndInspectorsComponent,
  AgenciesComponent,
  BuyersEngineersQaesComponent,
  BuyersEngineersQaesCreateComponent,
  BuyersEngineersQaesEditComponent,
  AgenciesAndInspectorsComponent,
  AgenciesComponent,
  InspectorsComponent,
  EditInspectorFormComponent,
  AddInspectorFormComponent,
  EditAgencyFormComponent,
  AddAgencyFormComponent,
  WorkRequestsComponent,
  ManufacturerAuditAndObservationComponent,
  ManufacturerAuditAndObservationTableComponent,
];

@NgModule({
  imports: [MenuModule],
  declarations: [componentList],
})
export class QaManagementModule { }

import { Component } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ICommodity } from "../../interfaces/administration/commodity";
import { VendorInformationDataService } from "../../services/vendor-information-data.service";
import { IVendorInformationData } from "../../interfaces/vendor-information/vendorInformationData";
import { UserRequestsDataService } from "../../services/administration/user-requests.service";
import { IUserData } from "../../interfaces/administration/userData";
import { ICommodityRating } from "../../interfaces/qa-management/commodityRating";
import { CommodityCodesService } from "../../services/administration/commodity-codes.service";
import { AuditObservationService } from "../../services/qa-management/audit-observation.service";
import { IAuditObservationEvaluationStatus } from "../../interfaces/qa-management/auditObservationEvaluationStatus";
import { IAuditObservationDetail } from "../../interfaces/qa-management/auditObservationDetailData";

export enum RatingType {
  Qualified = 'Qualified',
  ConditionallyQualified = 'Conditionally Qualified',
  NotQualified = 'Not Qualified',
  NotAvailable = 'Not Available'
}

@Component({
  selector: "manufacturer-audit-and-observation",
  templateUrl: "./manufacturer-audit-and-observation.component.html",
  styleUrls: ["./manufacturer-audit-and-observation.component.css"],
})
export class ManufacturerAuditAndObservationComponent {
  title = "Manufacturer Audit And Observation";

  searchFiltersForm: FormGroup;
  selectedCommodityObjects: ICommodity[] = [];
  validationMessages = [];
  manufacturerDropdownData: IVendorInformationData[] = [];
  leadsDropdownData: IUserData[] = [];
  commodityRatingsDropdownData: ICommodityRating[] = [];
  auditObservationStatusesDropdownData: IAuditObservationEvaluationStatus[] = [];
  auditObservationDetailsTableData: IAuditObservationDetail[] = [];
  auditObservationDetailsTableFilteredData: IAuditObservationDetail[] = [];
  dataForTableLoading = false;
  isSearchedLaunched = false;

  qualifiedData: IAuditObservationDetail[] = [];
  conditionallyQualifiedData: IAuditObservationDetail[] = [];
  notQualifiedData: IAuditObservationDetail[] = [];
  notAvailableData: IAuditObservationDetail[] = [];

  constructor(
    private fb: FormBuilder,
    private vendorInformationDataService: VendorInformationDataService,
    private userRequestsDataService: UserRequestsDataService,
    private commodityCodesService: CommodityCodesService,
    private auditObservationService: AuditObservationService
  ) { }

  ngOnInit(): void {
    this.getManufacturers();
    this.getLeads();
    this.getCommodityRatings();
    this.getEvaluationStatuses();
    this.searchFiltersForm = this.fb.group({
      manufacturerId: [-1],
      leadName: [''],
      commodityIds: [null],
      recordType: [''],
      rating: [''],
      dateFrom: [null],
      dateTo: [null],
      statusId: [-1]
    });
  }

  onClearFilters(): void {
    this.searchFiltersForm.reset({
      manufacturerId: -1,
      leadName: '',
      commodityIds: null,
      recordType: '',
      rating: '',
      dateFrom: null,
      dateTo: null,
      statusId: -1
    });
  }

  onAddAuditObservation(): void {
    // Handle add audit observation logic
  }

  handleSelectedCommodityNames(selectedCommodityNames: ICommodity[]) {
    this.selectedCommodityObjects = selectedCommodityNames;
  }

  getManufacturers(): void {
    this.vendorInformationDataService.getAllManufacturerVendors()
      .subscribe(response => {
        this.vendorInformationDataService.changeVendorManufacturerData(response);
        this.manufacturerDropdownData = response;
      }, error => {
        console.error(error);
      });
  }

  getLeads(): void {
    this.userRequestsDataService.getAllActiveUsers().subscribe(response => {
      this.leadsDropdownData = response;
    });
  }

  getCommodityRatings(): void {
    this.commodityCodesService.getAllCommodityResults().subscribe(response => {
      this.commodityRatingsDropdownData = response;
    });
  }

  getEvaluationStatuses(): void {
    this.auditObservationService.getAllEvaluationStatus().subscribe(response => {
      this.auditObservationStatusesDropdownData = response;
    });
  }

  getAllDetails(): void {
    this.dataForTableLoading = true;
    const filters = this.searchFiltersForm.value;

    if (filters.dateFrom) {
      filters.dateFrom = new Date(filters.dateFrom).toISOString();
    }
    if (filters.dateTo) {
      filters.dateTo = new Date(filters.dateTo).toISOString();
    }

    this.auditObservationService.getAllDetails(filters).subscribe(response => {
      this.auditObservationDetailsTableData = response;
      this.isSearchedLaunched = true;
      this.qualifiedData = response.filter(item => item.rating == RatingType.Qualified);
      this.conditionallyQualifiedData = response.filter(item => item.rating == RatingType.ConditionallyQualified);
      this.notQualifiedData = response.filter(item => item.rating == RatingType.NotQualified);
      this.notAvailableData = response.filter(item => item.rating == RatingType.NotAvailable);
      this.dataForTableLoading = false;
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

@Component({
  selector: 'alerts-component',
  templateUrl: './alerts.component.html'
})
export class AlertsComponent {
  title = "Administration";
  subtitle = "Alerts"
  links: any[] = [];

  constructor(private http: HttpClient) { }

}

import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

@Component({
  selector: 'help-component',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css'] 
})
export class HelpComponent {
  links: any[] = [];

  constructor(private http: HttpClient) { }

}

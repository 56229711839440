// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.validation-summary {
    color: #721c24;
    background-color: #f8d7da;
    position: relative;
    padding: 0.1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid #f5c6cb;
    border-radius: 0.25rem;
  }`, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/validation/validation.component.css"],"names":[],"mappings":";AACA;IACI,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,oBAAoB;IACpB,mBAAmB;IACnB,yBAAyB;IACzB,sBAAsB;EACxB","sourcesContent":["\n.validation-summary {\n    color: #721c24;\n    background-color: #f8d7da;\n    position: relative;\n    padding: 0.1rem 1rem;\n    margin-bottom: 1rem;\n    border: 1px solid #f5c6cb;\n    border-radius: 0.25rem;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

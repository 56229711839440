import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ILookupData } from "../../../interfaces/administration/lookupData";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CountriesAndStatesService } from "../../../services/countries-states.service";

@Component({
  selector: "app-create-country",
  templateUrl: "./create-country.component.html",
})
export class CreateCountryComponent implements OnInit {
  data: ILookupData;
  createForm: FormGroup;
  validationMessages = [];

  constructor(
    private countriesAndStatesService: CountriesAndStatesService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.createForm = this.fb.group({
      countryName: ["", Validators.required],
    });
  }

  submitForm(): void {
    let messagesValidationsList: string[] = [];
    let formCountryName: string = this.createForm.get('countryName').value;

    if (formCountryName === "")
      messagesValidationsList.push('Please enter the name of the country.');

    this.validationMessages = messagesValidationsList;

    if (this.createForm.valid && this.validationMessages.length === 0) {
      this.data = {
        name: formCountryName,
        description: formCountryName,
      };

      this.countriesAndStatesService.createCountry(this.data).subscribe({
        next: () => {
          this.backToCountriesAndStates();
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  cancel(): void {
    this.backToCountriesAndStates();
  }

  private backToCountriesAndStates() {
    this.router.navigate(["administration/countries-states"]);
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, take } from "rxjs";
import { IVendorData } from "../interfaces/administration/vendorData";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { FormGroup } from "@angular/forms";
import { ICommoditySearchData } from "../interfaces/vendor-information/ICommoditySearchData";
import { IVendorCommentData } from "../interfaces/vendor-information/vendorCommentData";
import { IViewVendorCommodityData } from "../interfaces/vendor-information/viewVendorCommodityData";

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class VendorDataService {
  private vendorDataSource = new BehaviorSubject<IVendorData | null>(null);
  currentVendorData = this.vendorDataSource.asObservable();

  constructor(private http: HttpClient) {}

  changeVendorData(vendorData: IVendorData) {
    this.vendorDataSource.next(vendorData);
  }

  public getVendorData(): Observable<IVendorData> {
    return this.vendorDataSource.asObservable();
  }

  public getVendors(): Observable<IVendorData[]> {
    return this.http.get<IVendorData[]>(`${apiUrl}/vendors?pagesize=10000`);
  }

  public getVendorById(id: string): Observable<IVendorData> {
    return this.http.get<IVendorData>(`${apiUrl}/vendors/${id}`);
  }

  public getVendorCommentData(id: string): Observable<IVendorCommentData> {
    return this.http.get<IVendorCommentData>(
      `${apiUrl}/vendors/${id}/comments`
    );
  }

  public getVendorCommodityData(
    id: string
  ): Observable<IViewVendorCommodityData[]> {
    return this.http.get<IViewVendorCommodityData[]>(
      `${apiUrl}/vendors/${id}/commodities`
    );
  }

  public createUpdateVendorComment(
    vendorCommentForm: FormGroup,
    vendorId: string,
    updatedBy: string
  ): Observable<IVendorCommentData> {
    const commentData: IVendorCommentData = {
      vendorId: Number(vendorId),
      plantReviewRevisions: vendorCommentForm.get("plantReviewRevisions").value,
      recommendations: vendorCommentForm.get("recommendations").value,
      observedQuality: vendorCommentForm.get("observedQuality").value,
      qualityControl: vendorCommentForm.get("qualityControl").value,
      updatedBy: updatedBy,
    };
    console.log(commentData);
    return this.http.post<IVendorCommentData>(
      `${apiUrl}/vendors/comments`,
      commentData
    );
  }

  public saveVendor(vendorData: IVendorData): Observable<any> {
    return this.http.post<IVendorData>(`${apiUrl}/vendors`, vendorData);
  }

  public updateVendor(
    id: string,
    vendorData: IVendorData
  ): Observable<IVendorData> {
    return this.http.put<IVendorData>(`${apiUrl}/vendors/${id}`, vendorData);
  }

  public activateDeactivateVendor(id: string): Observable<any> {
    return this.http.put(`${apiUrl}/vendors/${id}/activate-deactivate`, null);
  }

  public searchVendors(vendorName: string): Observable<IVendorData[]> {
    return this.http
      .get<IVendorData[]>(`${apiUrl}/vendors/match/${vendorName}`)
      .pipe(take(1));
  }

  public searchVendorCommodity(): Observable<ICommoditySearchData[]> {
    return this.http.get<ICommoditySearchData[]>(
      `${apiUrl}/vendors/commodity-search`
    );
  }

  public validateForm(form: FormGroup): string[] {
    let messagesValidationsList: string[] = [];

    if (form.get("vendorName").value === "") {
      messagesValidationsList.push("Vendor Name is required.");
    }

    if (form.get("city").value === "") {
      messagesValidationsList.push("City is required.");
    }

    if (form.get("countryId").value === "") {
      messagesValidationsList.push("Country is required.");
    }

    if (form.get("streetAddress").value === "") {
      messagesValidationsList.push("Street Address is required.");
    }

    if (
      form.get("acknowledgeScreening") != null &&
      form.get("acknowledgeScreening").value !== true
    ) {
      messagesValidationsList.push("Vendor Screened is required.");
    }

    return messagesValidationsList;
  }
}

import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BusOpcosDataService } from "../../services/administration/bus-opcos-data-service";
import { PurchaserManagementService } from "../../services/administration/purchaser-management.service";
import { CapitalProjectsDataService } from "../../services/capital-projects.service";
import {
  ICapitalProjectUsersData,
  IExtendedCapitalProjectData,
} from "../../interfaces/administration/capitalProjectUsersData";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { TableSortService } from "../../services/table-sort.service";
import { Sort } from "@angular/material/sort";
import { UserRequestsDataService } from "../../services/administration/user-requests.service";
import { IUserData } from "../../interfaces/administration/userData";
import { GraphService } from "../../services/administration/graph.service";

@Component({
  selector: "project-members-component",
  templateUrl: "./project-members.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `,
  ],
})
export class ProjectMembersComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public title = "Create new project";
  public dataSource: MatTableDataSource<IExtendedCapitalProjectData>;
  public originalDataSource: MatTableDataSource<IExtendedCapitalProjectData>;
  public dataSourceLoading: boolean = false;
  public displayedColumns: string[] = ["userId", "isProjectAdmin"];
  public users: IUserData[];
  public selectedUser: number = 0;

  constructor(
    private tableSortService: TableSortService,
    private capitalProjectsDataService: CapitalProjectsDataService,
    private changeDetectorRefs: ChangeDetectorRef,
    private graphService: GraphService
  ) {}

  private updateDataSource(members: ICapitalProjectUsersData[]) {
    if (!this.users) {
      console.error("Users data is not loaded yet.");
      return;
    }

    const extendedMembers = members.map((member) => {
      const user = this.users.find((u) => u.userId === member.userId);
      return {
        ...member,
        firstName: user ? user.firstName : "",
        lastName: user ? user.lastName : "",
      } as IExtendedCapitalProjectData;
    });

    this.originalDataSource =
      new MatTableDataSource<IExtendedCapitalProjectData>(extendedMembers);
    this.dataSource = new MatTableDataSource<IExtendedCapitalProjectData>(
      extendedMembers
    );
    this.dataSource.paginator = this.paginator;
    this.paginator.pageSize = 10;
    this.changeDetectorRefs.detectChanges();

    this.dataSourceLoading = false;
  }

  loadProjectMembers() {
    this.capitalProjectsDataService.loadProjectMembers$.subscribe((members) => {
      this.dataSourceLoading = true;

      this.graphService.getAllUsers().subscribe((users) => {
        this.users = users;
        this.updateDataSource(members);
      });
    });
  }

  ngOnInit(): void {
    this.dataSourceLoading = true;
    this.dataSource = new MatTableDataSource<IExtendedCapitalProjectData>();
    this.originalDataSource =
      new MatTableDataSource<IExtendedCapitalProjectData>();

    this.graphService.getAllUsers().subscribe((users) => {
      this.users = users;

      this.capitalProjectsDataService.currentCapitaProject$.subscribe(
        (data) => {
          if (data) {
            this.capitalProjectsDataService
              .loadMembers(data.projectId)
              .subscribe((members) => {
                this.updateDataSource(members);
              });
          }
        }
      );
    });
  }

  addMember() {
    this.dataSourceLoading = true;

    this.capitalProjectsDataService.currentCapitaProject$.subscribe((data) => {
      if (data) {
        this.capitalProjectsDataService
          .addProjectMember(data.projectId, this.selectedUser)
          .subscribe(() => {
            this.capitalProjectsDataService
              .loadMembers(data.projectId)
              .subscribe((members) => {
                this.updateDataSource(members);
              });
          });
      }
    });
  }

  announceSortChange(event: Sort) {
    this.dataSource.data = this.tableSortService.sortDataSource(
      this.originalDataSource.data,
      event
    );
  }
}

import { Component } from "@angular/core";
import { IUserData } from "../../../interfaces/administration/userData";
import { MatTableDataSource } from "@angular/material/table";
import { TableSortService } from "../../../services/table-sort.service";
import { Sort } from "@angular/material/sort";
import { UserRequestsDataService } from "../../../services/administration/user-requests.service";
import { Router } from "@angular/router";
import { AdministrationLoadingDataService } from "../../../services/administration/administration-loading-data-service";

@Component({
  selector: "user-requests-component",
  templateUrl: "./user-requests-component.html",
})
export class UserRequestComponent {
  dataSource: MatTableDataSource<IUserData>;
  originalDataSource: MatTableDataSource<IUserData>;

  displayedColumns = [
    "action",
    "lastName+firstName+cai",
    "email",
    "requestedComments",
  ];

  dataSourceLoading: boolean = true;

  constructor(
    private tableSortService: TableSortService,
    private userRequestsDataService: UserRequestsDataService,
    private administrationLoadingDataService: AdministrationLoadingDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<IUserData>();
    if (this.dataSource.data.length === 0) {
      this.loadUserRequests();
    }

    this.administrationLoadingDataService.loadUserRequestsData$.subscribe({
      next: () => {
        // This will be triggered whenever loadUserRequestsData signal is sent
        this.loadUserRequests(); // Call the method to reload data
      },
      error: (err) => console.error(err),
    });
  }

  announceSortChange(event: Sort) {
    this.dataSource.data = this.tableSortService.sortDataSource(
      this.originalDataSource.data,
      event
    );
  }

  editUserRequest(user: IUserData) {
    this.userRequestsDataService.editUserRequestsData(user);
    this.router.navigate([
      "/administration/adminHome/user-requests/edit",
      user.userId,
    ]);
  }

  loadUserRequests() {
    this.userRequestsDataService.getPendingUserRequests().subscribe({
      next: (data) => {
        this.dataSourceLoading = true;

        this.originalDataSource = new MatTableDataSource<IUserData>(data);
        this.dataSource = new MatTableDataSource<IUserData>(
          this.originalDataSource.data
        );

        this.dataSourceLoading = false;
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
}

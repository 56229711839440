// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }

      .form-check-inline {
        display: inline-block;
        margin-right: 10px;
      }

      .form-group.row.align-items-center {
        display: flex;
        align-items: center;
      }
    `, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/vendor-information/vendor-search/vendor-search.component.ts"],"names":[],"mappings":";MACM;QACE,wBAAwB;QACxB,yCAAyC;MAC3C;;MAEA;QACE,qBAAqB;QACrB,kBAAkB;MACpB;;MAEA;QACE,aAAa;QACb,mBAAmB;MACrB","sourcesContent":["\n      .button-container {\n        display: flex !important;\n        justify-content: space-between !important;\n      }\n\n      .form-check-inline {\n        display: inline-block;\n        margin-right: 10px;\n      }\n\n      .form-group.row.align-items-center {\n        display: flex;\n        align-items: center;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

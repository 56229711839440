import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { ILookupData } from "../../interfaces/administration/lookupData";
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class BusOpcosDataService {
  constructor(private http: HttpClient) {}

  public addBU(lookupData: ILookupData, opcoId: number): Observable<any> {
    return this.http.post<ILookupData>(
      `${apiUrl}/lookups/${opcoId}/addBU`,
      lookupData
    );
  }

  public addOpco(lookupData: ILookupData): Observable<any> {
    return this.http.post<ILookupData>(`${apiUrl}/lookups/addOpco`, lookupData);
  }

  public updateBU(lookupData: ILookupData): Observable<any> {
    return this.http.put<ILookupData>(`${apiUrl}/lookups`, lookupData);
  }

  public updateOpco(lookupData: ILookupData): Observable<ILookupData> {
    return this.http.put<ILookupData>(`${apiUrl}/lookups`, lookupData);
  }

  public getOpcoById(opcoId: number): Observable<ILookupData> {
    return this.http.get<ILookupData>(`${apiUrl}/operatingCompanies/${opcoId}`);
  }

  public getBUById(buId: number): Observable<ILookupData> {
    return this.http.get<ILookupData>(`${apiUrl}/businessUnits/${buId}`);
  }

  public getOpcos(): Observable<ILookupData[]> {
    return this.http.get<ILookupData[]>(`${apiUrl}/operatingCompanies`);
  }

  public getBUsByOpcoId(opcoId: number): Observable<ILookupData[]> {
    return this.http.get<ILookupData[]>(`${apiUrl}/${opcoId}/businessUnits`);
  }

  public getActiveBUs(): Observable<ILookupData[]> {
    return this.http.get<ILookupData[]>(`${apiUrl}/businessUnits/active`);
  }
}

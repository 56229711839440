// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tree-progress-bar {
  margin-left: 30px;
}

.mat-tree-node {
  padding: 0;
  white-space: nowrap;
}

.node-content {
  display: flex;
  align-items: center;
}

.node-content span {
  margin-right: 10px;
}

.tree-progress-bar {
  flex-shrink: 0;
}

.selected-node {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/administration/commodity-codes/commodity-codes-selectable-tree/commodity-codes-selectable-tree.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".tree-progress-bar {\n  margin-left: 30px;\n}\n\n.mat-tree-node {\n  padding: 0;\n  white-space: nowrap;\n}\n\n.node-content {\n  display: flex;\n  align-items: center;\n}\n\n.node-content span {\n  margin-right: 10px;\n}\n\n.tree-progress-bar {\n  flex-shrink: 0;\n}\n\n.selected-node {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

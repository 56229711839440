import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ICommodity } from '../../../interfaces/administration/commodity';
import { CommodityCodesService } from '../../../services/administration/commodity-codes.service';
import { CommodityViewMode } from '../../../interfaces/administration/commodityViewMode';
import { UserRequestsDataService } from '../../../services/administration/user-requests.service';
import { IUserData } from '../../../interfaces/administration/userData';

@Component({
  selector: 'edit-commodity',
  templateUrl: './edit-commodity.component.html',
  styleUrls: ["../commodity-codes.component.css"],

})
export class EditCommodityComponent {
  public commoditiesData!: MatTableDataSource<ICommodity>;
  public smeUsersData!: IUserData[];
  public choosenNode: ICommodity;
  commodityForm: FormGroup;
  loadingSME = false;
  smeOptions = [{ id: 0, name: '-- NO SME SELECTION --' }];

  constructor(private commodityCodesService: CommodityCodesService, private userRequestService: UserRequestsDataService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.commodityForm = this.fb.group({
      commodityCodeLevel1: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(1), Validators.maxLength(2)]],
      commodityCodeLevel2: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(1), Validators.maxLength(2)]],
      commodityCodeLevel3: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(1), Validators.maxLength(2)]],
      commodityCodeLevel4: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(1), Validators.maxLength(2)]],
      description: ['', Validators.required],
      sme: [0, Validators.required]
    });
    this.commodityCodesService.choosenNode$.subscribe((node) => {
      this.choosenNode = node;
      this.commodityForm.setValue({
        commodityCodeLevel1: this.choosenNode.commodityCodeLevel1,
        commodityCodeLevel2: this.choosenNode.commodityCodeLevel2,
        commodityCodeLevel3: this.choosenNode.commodityCodeLevel3,
        commodityCodeLevel4: this.choosenNode.commodityCodeLevel4,
        description: this.choosenNode.description,
        sme: this.choosenNode.subjectMatterExpertUserId || 0,
      });
      switch (this.choosenNode.commodityLevel) {
        case 1:
          this.commodityForm.get('commodityCodeLevel1').enable();
          break;
        case 2:
          this.commodityForm.get('commodityCodeLevel2').enable();
          break;
        case 3:
          this.commodityForm.get('commodityCodeLevel3').enable();
          break;
        case 4:
          this.commodityForm.get('commodityCodeLevel4').enable();
          break;
      }
    });
    this.loadingSME = true;
    this.userRequestService.getSMEUsers().subscribe({
      next: (data) => {
        this.smeUsersData = data;
        this.smeOptions.push(...this.smeUsersData.map(user => ({
          id: user.userId,
          name: user.cai + ': ' + user.firstName + ' ' + user.lastName,
        })));
        this.loadingSME = false;
      },
      error: (error) => {
        this.loadingSME = false;
        console.error(error);
      },
    });
  }

  changeViewMode(viewMode: CommodityViewMode): void {
    this.commodityCodesService.updateCommodityViewMode(viewMode);
  }

  saveEditCommodity() {
    if (this.commodityForm.valid) {
      var updatedCommodity: ICommodity = { ...this.choosenNode };

      updatedCommodity = {
        ...updatedCommodity,
        commodityCodeLevel1: this.commodityForm.get('commodityCodeLevel1').value,
        commodityCodeLevel2: this.commodityForm.get('commodityCodeLevel2').value,
        commodityCodeLevel3: this.commodityForm.get('commodityCodeLevel3').value,
        commodityCodeLevel4: this.commodityForm.get('commodityCodeLevel4').value,
        description: this.commodityForm.get('description').value,
        subjectMatterExpertUserId: this.commodityForm.get('sme').value,
      };

      this.commodityCodesService.updateCommodity(this.choosenNode.commodityId.toString(), updatedCommodity).subscribe((data) => {
        this.commodityCodesService.getAllCommodities();
      });
      this.changeViewMode(CommodityViewMode.Base);
    }
  }

  cancelEditCommodity() {
    this.changeViewMode(CommodityViewMode.Information);
  }

  smeCaiAndName(node: ICommodity): string {
    return node.smecai + ': ' + node.smefirstName + ' ' + node.smelastName;
  }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IGroupMember } from '../../interfaces/administration/groupMemberData';
import { BehaviorSubject, Observable } from 'rxjs';
import { IUserData } from '../../interfaces/administration/userData';

@Injectable({
    providedIn: 'root'
})
export class GraphService {
    private apiUrl = environment.apiUrl + '/graph';

    private currentUserDataSubject: BehaviorSubject<IUserData> =
        new BehaviorSubject(null);
    currentUserData$: Observable<IUserData> =
        this.currentUserDataSubject.asObservable();

    constructor(private http: HttpClient) { }

    public getMembersOfGroup(): Observable<IGroupMember[]> {
        const url = `${this.apiUrl}/getMembersOfGroup`;
        return this.http.get<IGroupMember[]>(url);
    }

    public getAllUsers(): Observable<IUserData[]> {
        const url = `${this.apiUrl}/getAllUsers`;
        return this.http.get<IUserData[]>(url);
    }

    public updateUserData(data: IUserData): void {
        this.currentUserDataSubject.next(data);
    }

    public updateUser(
        userData: IUserData
    ): Observable<IUserData> {
        return this.http.put<IUserData>(
            `${this.apiUrl}/updateUser`,
            userData
        );
    }

    public searchForUserInEntra(
        searchText: String): Observable<IUserData[]> {
        const url = `${this.apiUrl}/search/${searchText}`;
        return this.http.get<IUserData[]>(url);
    }

}


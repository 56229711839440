import { Component, Input } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

export interface ButtonCellRendererParams extends ICellRendererParams {
  buttonText?: string;
  action?: () => void;
}

@Component({
  selector: "action-button-component",
  templateUrl: "./action-button.component.html",
  styles: [
    `
      .dng-button {
        background-color: #97002e;
        border-color: #97002e;
      }
      .small-icon {
        font-size: 20px;
      }
    `,
  ],
})
export class ActionButtonComponent {
  value: any;
  @Input() buttonText: string;
  @Input() element: any;
  @Input() danger: boolean = false;
  @Input() classes: string = "";
  @Input() icon: string = "";
  @Input() label: string = "";
  actionFrom: () => void;

  buttonOnClick() {
    this.actionFrom ? this.actionFrom() : {};
  }
}

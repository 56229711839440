import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Sort } from "@angular/material/sort";
import { TableSortService } from "projects/aqua3/src/app/services/table-sort.service";
import { IAq2QaqcbuyerEngineerQae } from "../../interfaces/qa-management/aq2QaqcbuyerEngineerQae";
import { MatPaginator } from "@angular/material/paginator";
import { QaqcbuyerEngineerQaeService } from "../../services/qaqc-buyer-engineer-qae.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "buyers-engineers-qaes-component",
  templateUrl: "./buyers-engineers-qaes-component.html",
  styles: [
    `
      .form-check-inline {
        display: inline-block;
        margin-right: 10px;
      }

      .form-group.row.align-items-center {
        display: flex;
        align-items: center;
      }
    `,
  ],
})
export class BuyersEngineersQaesComponent implements OnInit {
  title = "Agencies";
  public dataSource: MatTableDataSource<IAq2QaqcbuyerEngineerQae>;
  public originalDataSource: MatTableDataSource<IAq2QaqcbuyerEngineerQae>;
  public listingsForm: FormGroup;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  public displayedColumns: string[] = [
    "actions",
    "name",
    "role",
    "phoneNumber",
    "address",
    "email",
    "active",
  ];

  dataSourceLoading: boolean = true;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private service: QaqcbuyerEngineerQaeService
  ) {
    this.listingsForm = this.fb.group({
      name: [""],
      role: ["all"],
      email: [""],
    });
  }

  ngOnInit(): void {
    this.updateListings();
  }

  updateListings() {
    this.service.getQaqcbuyerEngineerQae().subscribe({
      next: (data) => {
        this.dataSourceLoading = true;

        this.originalDataSource =
          new MatTableDataSource<IAq2QaqcbuyerEngineerQae>(data);
        this.dataSource = new MatTableDataSource<IAq2QaqcbuyerEngineerQae>(
          this.originalDataSource.data
        );

        this.applyFilters();

        this.dataSourceLoading = false;
        // Delay the initialization of the paginator until the data is assigned
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.paginator.pageSize = 10;
        });
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  applyFilters() {
    const { name, role, email } = this.listingsForm.value;

    this.dataSource.data = this.originalDataSource.data.filter((item) => {
      return (
        (name
          ? item.firstName.toLowerCase().includes(name.toLowerCase())
          : true) &&
        (role === "all" ||
          (role === "buyer" && item.isBuyer) ||
          (role === "engineer" && item.isEngineer) ||
          (role === "qae" && item.isQae)) &&
        (email ? item.email.toLowerCase().includes(email.toLowerCase()) : true)
      );
    });
  }

  clearFilters() {
    this.listingsForm.reset({
      name: "",
      role: "all",
      email: "",
    });
    this.applyFilters();
  }

  addBuyerEngineerQae() {
    this.router.navigate(["/qa-management/buyers-engineers-qaes/create"]);
  }

  edit(data: IAq2QaqcbuyerEngineerQae) {
    this.service.setCurrentAq2QaqcbuyerEngineerQae(data);
    this.router.navigate(["/qa-management/buyers-engineers-qaes/edit"]);
  }
}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ILookupData } from "../interfaces/administration/lookupData";
import { BusOpcosDataService } from "../services/administration/bus-opcos-data-service";
import { ICapitalProjectData } from "../interfaces/administration/capitalProjectData";
import { PurchaserManagementService } from "../services/administration/purchaser-management.service";
import { CapitalProjectsDataService } from "../services/capital-projects.service";

@Component({
  selector: "edit-project-component",
  templateUrl: "./edit-project.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `,
  ],
})
export class EditProjectComponent implements OnInit {
  public title = "Create new project";
  public projectData: ICapitalProjectData;
  public isLoading = false;
  public projectForm: FormGroup;
  public validationMessages = [];
  public opcoids: ILookupData[] = [];
  public businessUnits: ILookupData[] = [];
  public purchasers: ILookupData[] = []; // Add the purchasers array back

  constructor(
    private service: BusOpcosDataService,
    private capitalProjectsDataService: CapitalProjectsDataService,
    private purchaserService: PurchaserManagementService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.projectData = {
      projectId: 0,
      longName: "",
      shortName: "",
      opcoid: 0,
      sbuid: 0,
      description: "",
      location: "",
      epcid: 0,
      isProjectArchived: false,
    };
  }

  ngOnInit(): void {
    this.capitalProjectsDataService.currentCapitaProject$.subscribe((data) => {
      if (data) {
        this.projectData = data;
        this.createForm();
        this.getOpcos();
        this.getPurchasers();

        // Call onOpcoChange() after the form has been created and the project data has been set
        this.onOpcoChange(this.projectData.opcoid);

        this.projectForm.get("opco").valueChanges.subscribe((selectedOpco) => {
          if (selectedOpco) {
            this.onOpcoChange(selectedOpco);
          }
        });

        this.projectForm.patchValue(data); // This will set the values in the form
      }
    });
  }

  createForm() {
    this.projectForm = this.fb.group({
      projectName: [this.projectData.longName, Validators.required],
      projectCodeName: [this.projectData.shortName, Validators.required],
      projectLocation: [this.projectData.location, Validators.required],
      opco: [this.projectData.opcoid, Validators.required],
      businessUnit: [
        { value: this.projectData.sbuid, disabled: false },
        Validators.required,
      ],
      projectDescription: [this.projectData.description, Validators.required],
      purchaser: [this.projectData.epcid, Validators.required],
    });
  }

  getOpcos() {
    this.service.getOpcos().subscribe((data: ILookupData[]) => {
      this.opcoids = data;
    });
  }

  getPurchasers() {
    this.purchaserService.getPurchasers().subscribe((data: ILookupData[]) => {
      this.purchasers = data;
    });
  }

  onOpcoChange(opcoId: number) {
    if (opcoId > 0) {
      this.service.getBUsByOpcoId(opcoId).subscribe((data: ILookupData[]) => {
        this.businessUnits = data;
        this.projectForm.get("businessUnit").enable();
        // Set the value of the 'businessUnit' control
        if (this.projectData && this.projectData.sbuid) {
          this.projectForm.get("businessUnit").setValue(this.projectData.sbuid);
        }
      });
    } else {
      this.projectForm.get("businessUnit").disable();
    }
  }

  onSubmit() {
    this.isLoading = true;

    this.validationMessages = this.capitalProjectsDataService.validateForm(
      this.projectForm
    );

    if (this.projectForm.valid && this.validationMessages.length === 0) {
      this.projectData.longName = this.projectForm.get("projectName").value;
      this.projectData.shortName =
        this.projectForm.get("projectCodeName").value;
      this.projectData.location = this.projectForm.get("projectLocation").value;
      this.projectData.description =
        this.projectForm.get("projectDescription").value;
      this.projectData.opcoid = this.projectForm.get("opco").value;
      this.projectData.sbuid = this.projectForm.get("businessUnit").value;
      this.projectData.epcid = this.projectForm.get("purchaser").value; // Use the selected purchaser

      this.capitalProjectsDataService.updateProject(this.projectData).subscribe({
        next: (data) => {
          if (data) {
            this.router.navigate(["/approved-vendor-list"]);
          }
        },
        error: (error) => {
          this.validationMessages.push(error);
        },
        complete: () => {},
      });
    }
  
    this.isLoading = false;
  }

  cancelRequest() {
    this.router.navigate(["/approved-vendor-list"]);
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ProjectAndAvlService } from "../../services/administration/project-and-avl-service";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ProjectsAndAvls } from "../../interfaces/administration/projectsAndAvls";
@Component({
  selector: "app-project-and-avl",
  templateUrl: "./project-and-avl.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `,
  ],
})
export class ProjectAndAvlComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public dataSource: MatTableDataSource<ProjectsAndAvls>;
  public displayedColumns: string[] = [
    "actions",
    "name",
    "codename",
    "avlname",
    "actionsavl",
  ];
  public isLoading = true;

  constructor(
    private service: ProjectAndAvlService,
    private router: Router,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loadData();
    this.paginator.pageSize = 10;
    this.sort.active = "name";
    this.sort.direction = "asc";
    this.sort.sortChange.emit({
      active: this.sort.active,
      direction: this.sort.direction,
    });
  }

  ngAfterViewInit() {}

  loadData() {
    this.isLoading = true;
    this.service.getData().subscribe((data) => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.paginator.pageSize = 10;
      this.isLoading = false;
      this.changeDetectorRefs.detectChanges();
    });
  }

  archiveAvl(data: ProjectsAndAvls) {
    this.service.archiveAvl(data).subscribe((data) => {
      this.loadData();
    });
  }

  archiveProject(data: ProjectsAndAvls) {
    this.service.archiveProject(data).subscribe((data) => {
      this.loadData();
    });
  }

  confirmDelete(data: ProjectsAndAvls) {
    if (window.confirm("Are you sure you want to delete this AVL?")) {
      this.deleteAvl(data);
    }
  }

  deleteAvl(data: ProjectsAndAvls) {
    this.isLoading = true;
    this.service.archiveAvl(data).subscribe((result) => {
      this.loadData();
    });
  }

  confirmDeleteProject(data: ProjectsAndAvls) {
    if (window.confirm("Are you sure you want to delete this Project?")) {
      this.deleteProject(data);
    }
  }

  deleteProject(data: ProjectsAndAvls) {
    this.isLoading = true;
    this.service.archiveProject(data).subscribe((result) => {
      this.loadData();
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'image-with-title-component',
  templateUrl: './image-with-title.component.html'
})
export class ImageWithTitleComponent {
  @Input() title = "";
  @Input() subtitle = ""
  links: any[] = [];

  constructor(private http: HttpClient) { }

}

import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AdministrationLoadingDataService {
  private loadUserRequestsDataSubject = new Subject<void>();
  loadUserRequestsData$: Observable<void> =
    this.loadUserRequestsDataSubject.asObservable();

  private loadRejectedCommodityRequestsDataSubject = new Subject<void>();
  loadRejectedCommodityRequestsData$: Observable<void> =
    this.loadRejectedCommodityRequestsDataSubject.asObservable();

  private loadPendingCommodityRequestsDataSubject = new Subject<void>();
  loadPendingCommodityRequestsData$: Observable<void> =
    this.loadPendingCommodityRequestsDataSubject.asObservable();

  private loadApprovedCommodityRequestsDataSubject = new Subject<void>();
  loadApprovedCommodityRequestsData$: Observable<void> =
    this.loadApprovedCommodityRequestsDataSubject.asObservable();

  loadUserRequestsData(): void {
    this.loadUserRequestsDataSubject.next();
  }

  loadRejectedCommodityRequestsData(): void {
    this.loadRejectedCommodityRequestsDataSubject.next();
  }

  loadPendingCommodityRequestsData(): void {
    this.loadPendingCommodityRequestsDataSubject.next();
  }

  loadApprovedCommodityRequestsData(): void {
    this.loadApprovedCommodityRequestsDataSubject.next();
  }
}

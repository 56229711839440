import { Component, ViewChild } from '@angular/core';
import { CommodityViewMode } from '../../interfaces/administration/commodityViewMode';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VendorInformationDataService } from '../../services/vendor-information-data.service';
import { TableSortService } from '../../services/table-sort.service';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IVendorInformationData } from '../../interfaces/vendor-information/vendorInformationData';
import { MatStepper } from '@angular/material/stepper';
import { IVendorCommodityData } from '../../interfaces/administration/vendorCommodityData';
import { CapitalProjectsDataService } from '../../services/capital-projects.service';
import { ICapitalProjectData } from '../../interfaces/administration/capitalProjectData';
import { VendorCommodityDataService } from '../../services/vendor-information/vendor-commodity.service';
import { CommodityCodesService } from '../../services/administration/commodity-codes.service';
import { VendorCommodityRequest } from '../../interfaces/vendor-information/vendorCommodityRequest';
import { LoggedUserService } from '../../services/common/logged-user.service';
import { ILoggedUserData } from '../../interfaces/common/loggedUserData';
import { ICommodity } from '../../interfaces/administration/commodity';
import { forkJoin, map } from 'rxjs';

@Component({
  selector: 'add-commodity-to-vendor',
  templateUrl: './add-commodity-to-vendor.component.html',
  styleUrls: ["./add-commodity-to-vendor.component.css"],
})
export class AddCommodityToVendorComponent {
  @ViewChild('stepper') stepper: MatStepper;
  ViewMode = CommodityViewMode;
  title = "Add Commodity to Vendor";
  commoditySearchForm: FormGroup;
  validationMessages = [];

  firstFormGroup: FormGroup;
  secondManufacturerFormGroup: FormGroup;
  secondSupplierFormGroup: FormGroup;
  thirdManufacturerFormGroup: FormGroup;
  thirdSupplierFormGroup: FormGroup;
  fourthFormGroup: FormGroup;

  vendorTypes = ["manufacturer", "supplier"];
  endorseTypes = ["Endorsed by Project/BU", "Endorsed by SME"];
  displayedColumns: string[] = ['action', 'manufacturer', 'streetAddress', 'county', 'city', 'state', 'postalCode', 'country'];
  choosenVendor: IVendorInformationData;

  private oryginalData!: MatTableDataSource<IVendorInformationData>;
  public sortedData!: MatTableDataSource<IVendorInformationData>;
  sortedDataLength = 0;

  selectedOption = "";
  selectedVendorName = "";

  requestNewVendorCommodity: IVendorCommodityData;
  projects: ICapitalProjectData[] = [];
  isLoadingVendors = false;
  noResultsFound = false;
  selectedCommodityObjects: ICommodity[] = [];
  commodityTableData: ICommodity[] = [];
  defaultProjectValue = -1;

  currentUser: ILoggedUserData;
  vendorCommodityRequest: VendorCommodityRequest;

  constructor(private vendorInformationDataService: VendorInformationDataService,
    private fb: FormBuilder,
    private tableSortService: TableSortService,
    private capitalProjectsDataService: CapitalProjectsDataService,
    private vendorCommodityDataService: VendorCommodityDataService,
    private commodityCodesService: CommodityCodesService,
    private loggedUserService: LoggedUserService,
  ) { }

  ngOnInit() {
    this.firstFormGroup = this.fb.group({
      vendorTypeCtrl: ['', Validators.required]
    });
    this.secondManufacturerFormGroup = this.fb.group({
      manufacturerNameCtrl: ['', Validators.required]
    });
    this.secondSupplierFormGroup = this.fb.group({
      supplierNameCtrl: ['', Validators.required]
    });
    this.thirdManufacturerFormGroup = this.fb.group({
    });
    this.thirdSupplierFormGroup = this.fb.group({
    });
    this.fourthFormGroup = this.fb.group({
      imaNumberSupplier: [''],
      supplierDiversitySupplier: [false],
      imaNumberManufacturer: [''],
      supplierDiversityManufacturer: [false],
      brand: ['', Validators.required],
      project: [-1, Validators.required],
      endorser: ['', Validators.required]
    });

    this.loggedUserService.getLoggedUserData().subscribe((data) => {
      this.currentUser = data;
    });
  }

  getManufacturers() {
    this.oryginalData = new MatTableDataSource<IVendorInformationData>([]);
    this.sortedData = new MatTableDataSource<IVendorInformationData>([]);
    var manufacturerName = this.secondManufacturerFormGroup.get('manufacturerNameCtrl').value;
    if (manufacturerName) {
      this.isLoadingVendors = true;
      this.vendorInformationDataService.getManufacturerVendors(manufacturerName)
        .subscribe(response => {
          this.vendorInformationDataService.changeVendorManufacturerData(response);
          this.oryginalData = new MatTableDataSource<IVendorInformationData>(response);
          this.sortedData = new MatTableDataSource<IVendorInformationData>(response);
          this.isLoadingVendors = false;
          this.sortedDataLength = response.length;
          this.noResultsFound = this.sortedDataLength == 0;
        }, error => {
          console.error(error);
          this.isLoadingVendors = false;
        });
    } else {
      console.warn('Manufacturer name is empty');
    }
  }

  sortManufacturersChange(event: Sort) {
    if (this.oryginalData?.data.length > 0) {
      this.sortedData.data = this.tableSortService.sortDataSource(this.oryginalData.data, event);
    }
  }

  getSuppliers() {
    this.oryginalData = new MatTableDataSource<IVendorInformationData>([]);
    this.sortedData = new MatTableDataSource<IVendorInformationData>([]);
    var supplierName = this.secondSupplierFormGroup.get('supplierNameCtrl').value;
    if (supplierName) {
      this.isLoadingVendors = true;
      this.vendorInformationDataService.getSuppliersVendors(supplierName)
        .subscribe(response => {
          this.vendorInformationDataService.changeVendorSupplierData(response);
          this.oryginalData = new MatTableDataSource<IVendorInformationData>(response);
          this.sortedData = new MatTableDataSource<IVendorInformationData>(response);

          this.isLoadingVendors = false;
          this.sortedDataLength = response.length;
          this.noResultsFound = this.sortedDataLength == 0;
        }, error => {
          console.error(error);
          this.isLoadingVendors = false;
        });
    } else {
      console.warn('Supplier name is empty');
    }
  }

  resetFormControls() {
    this.fourthFormGroup.get('imaNumberSupplier').reset();
    this.fourthFormGroup.get('supplierDiversitySupplier').reset();
    this.fourthFormGroup.get('imaNumberManufacturer').reset();
    this.fourthFormGroup.get('supplierDiversityManufacturer').reset();
    this.fourthFormGroup.get('brand').reset();
    this.fourthFormGroup.get('project').reset();
    this.fourthFormGroup.get('endorser').reset();
  }

  chooseVendor(vendor: IVendorInformationData): void {
    this.choosenVendor = vendor;
    this.resetFormControls();
    let imaNumberSupplierControl = this.fourthFormGroup.get('imaNumberSupplier');
    let supplierDiversitySupplierControl = this.fourthFormGroup.get('supplierDiversitySupplier');
    let imaNumberManufacturerControl = this.fourthFormGroup.get('imaNumberManufacturer');
    let supplierDiversityManufacturerControl = this.fourthFormGroup.get('supplierDiversityManufacturer');

    // TODO: Implement this logic
    //     If(Authorization.CanAccessAdministration() Or Authorization.CanAccessQualityAssuranceAndControl()) Then
    //        data = New ProjectCRUD().GetAllProjects()
    //     Else
    //        data = New ProjectCRUD().GetProjectsAvailableToUser(Authorization.GetAQUA2ApplicationUserId)
    //      End If

    this.capitalProjectsDataService.getAllProjects().subscribe(response => {
      this.projects = response;
    });

    this.selectedVendorName = this.choosenVendor.vendorName;

    if (this.selectedOption == this.vendorTypes[0]) {
      imaNumberSupplierControl.disable();
      supplierDiversitySupplierControl.disable();
    } else {
      imaNumberManufacturerControl.disable();
      supplierDiversityManufacturerControl.disable();
    }
  }

  onVendorTypeChange(event: any): void {
    if (event?.value) {
      this.selectedOption = event.value;
    } else {
      this.selectedOption = "";
    }
  }

  goToFirstStep() {
    this.stepper.reset();
  }

  submitAnotherCommodity() {
    this.stepper.previous();
  }

  handleSelectedCommodityNames(selectedCommodityNames: ICommodity[]) {
    this.selectedCommodityObjects = selectedCommodityNames;
  }

  onSubmit() {
    var requestCommodityIds: number[] = [];
    this.validationMessages = [];

    if (!this.selectedCommodityObjects || this.selectedCommodityObjects.length == 0) {
      this.validationMessages.push("Please select a commodity to request.");
    } else {
      requestCommodityIds = this.selectedCommodityObjects.map(commodity => commodity.commodityId);
    }

    var requestProjectId = this.fourthFormGroup.get('project').value;
    if (requestProjectId == null || requestProjectId == -1) {
      this.validationMessages.push("Please select a project.");
    }

    var requestBrand = this.fourthFormGroup.get('brand').value;
    if (requestBrand == null || requestBrand == "") {
      this.validationMessages.push("Please insert brand.");
    }

    var requestEndorserSelectedValue = this.fourthFormGroup.get('endorser').value;
    if (!requestEndorserSelectedValue) {
      this.validationMessages.push("Please select an endorser for this commodity to vendor combination.");
    }

    if (requestCommodityIds != null && requestCommodityIds.length > 0) {
      for (let requestCommodityId in requestCommodityIds) {
        this.vendorCommodityDataService.getManufacturerCommodityCountByCommodityId(this.choosenVendor.vendorId.toString(), requestCommodityId)
          .subscribe(response => {
            if (response > 0) {
              var commodity = this.selectedCommodityObjects.find(x => x.commodityId.toString() == requestCommodityId);
              this.validationMessages.push("Commodity " + commodity.commodityCodeTrimmed + ' - ' + commodity.description + " already added to vendor.");
            }
          });
      }
    }

    if (this.validationMessages.length > 0) {
      this.scrollToTop();
      return;
    }

    this.vendorCommodityRequest = {
      SupplierId: null,
      SupplierRating: 0,
      SupplierRatingComment: '',
      ManufacturerId: null,
      ManufacturerRating: 0,
      ManufacturerRatingComment: '',
      BrandName: '',
      EndorsedByProjectId: null,
      CommodityIdList: [],
      RequestorId: 0,
      RequestorDate: new Date(),
      IsEndorsedBySME: false,
      IsActive: false,
      VendorCommodityId: 0,
      IsApproved: false,
      ApprovedUserID: 0,
      RejectedUserID: 0,
      ApprovedDate: new Date(),
      RejectedDate: new Date(),
      IsManufacturer: false,
      IsSupplier: false,
      SupplierIMANumber: '',
      SupplierSD: false,
      ManufacturerIMANumber: '',
      ManufacturerSD: false,
      DeactivationComments: '',
      ProjectName: '',
      CommodityCode: '',
      CommodityDescription: '',
    };

    // Manufacturer
    if (this.selectedOption == this.vendorTypes[0]) {
      this.vendorCommodityRequest.ManufacturerId = this.choosenVendor.vendorId;
      this.vendorCommodityRequest.ManufacturerIMANumber = this.fourthFormGroup.get('imaNumberManufacturer').value;
      this.vendorCommodityRequest.ManufacturerSD = this.fourthFormGroup.get('supplierDiversityManufacturer').value ? true : false;
    }

    // Supplier
    if (this.selectedOption == this.vendorTypes[1]) {
      this.vendorCommodityRequest.SupplierId = this.choosenVendor.vendorId;
      this.vendorCommodityRequest.SupplierIMANumber = this.fourthFormGroup.get('imaNumberSupplier').value;
      this.vendorCommodityRequest.SupplierSD = this.fourthFormGroup.get('supplierDiversitySupplier').value ? true : false;
    }

    // Brand
    this.vendorCommodityRequest.BrandName = this.fourthFormGroup.get('brand').value;

    this.vendorCommodityRequest.IsEndorsedBySME = true;
    if (requestEndorserSelectedValue == 0) {
      this.vendorCommodityRequest.IsEndorsedBySME = false;
      this.vendorCommodityRequest.EndorsedByProjectId = requestProjectId;
    }

    this.vendorCommodityRequest.ProjectName = this.projects.find(p => p.projectId == requestProjectId)?.shortName;
    this.vendorCommodityRequest.CommodityIdList = requestCommodityIds;
    this.vendorCommodityRequest.RequestorId = this.currentUser.userId;

    this.vendorCommodityDataService.insertVendorCommodityRequest(this.vendorCommodityRequest).subscribe(response => {
      this.commodityTableData = this.selectedCommodityObjects.filter(commodity => requestCommodityIds.includes(commodity.commodityId));

      const commodityPathObservables = this.commodityTableData.map(commodity =>
        this.commodityCodesService.getCommodityPath(commodity.commodityId.toString()).pipe(
          map(commodityPath => {
            commodity.commodityPath = commodityPath;
            return commodity;
          })
        )
      );

      forkJoin(commodityPathObservables).subscribe(updatedCommodities => {
        this.commodityTableData = updatedCommodities;
        this.stepper.next();
      });
    },
      error => {
        const errorMessage = error.error?.match(/Parameter '([^']+)'/);
        const extractedMessage = errorMessage ? errorMessage[1] : 'Unknown error';

        this.validationMessages.push('Error inserting vendor commodity request: ' + extractedMessage);
        this.scrollToTop();
      });
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
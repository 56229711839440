import { NgModule } from "@angular/core";
import { LeftMenuComponent } from "./left-menu/left-menu.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "../shared.module";
import { HeaderComponent, MenuComponent } from "@cvx/nextpage";
import { AppRoutingModule } from "../app-routing.module";

const componentList = [MenuComponent, HeaderComponent, LeftMenuComponent];

@NgModule({
  imports: [BrowserAnimationsModule, SharedModule, AppRoutingModule],
  declarations: [componentList],

  exports: [
    MenuComponent,
    HeaderComponent,
    LeftMenuComponent,
    SharedModule,
    AppRoutingModule,
  ],
})
export class MenuModule {}

import { NgModule } from "@angular/core";
import { MenuModule } from "../menu/menu.module";
import { AddVendorComponent } from "./add-vendor/add-vendor.component";
import { CreateNewVendorComponent } from "./add-vendor/create-new-vendor.component";
import { VendorSearchComponent } from "./vendor-search/vendor-search.component";
import { VendorInformationComponent } from "../vendor-information/vendor-information.component";
import { AddCommodityToVendorComponent } from "./add-commodity-to-vendor/add-commodity-to-vendor.component";
import { VendorDetailsComponent } from "./vendor-search/vendor-details.component";

const componentList = [
    AddVendorComponent,
    CreateNewVendorComponent,
    VendorSearchComponent,
    VendorDetailsComponent,
    VendorInformationComponent,
    AddCommodityToVendorComponent,
];

@NgModule({
    imports: [MenuModule],
    declarations: [componentList],
})
export class VendorInformationModule { }

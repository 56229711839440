import { OnInit, Component } from "@angular/core";

@Component({
  selector: "custom-error-page",
  templateUrl: "./custom-error-page.component.html",
})
export class CustomErrorComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

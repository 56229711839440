import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AgenciesAndInspectorsDataService } from "../../../services/qa-management/agencies-inspectors.service";
import { IAgencyData } from "../../../interfaces/qa-management/agencyData";
import { ILookupData } from "../../../interfaces/administration/lookupData";
import { CountriesAndStatesService } from "../../../services/countries-states.service";
import { LoggedUserService } from "../../../services/common/logged-user.service";
import { ILoggedUserData } from "../../../interfaces/common/loggedUserData";
import { CurrenciesDataService } from "../../../services/qa-management/currencies.service";
import { ICurrencyData } from "../../../interfaces/qa-management/currencyData";
import { Router } from "@angular/router";
@Component({
  selector: "add-agency-form.component",
  templateUrl: "./add-agency-form.component.html",
})
export class AddAgencyFormComponent implements OnInit {
  addForm: FormGroup;
  validationMessages = [];
  agencyData: IAgencyData;
  countryName: string = "";
  stateName: string = "";
  lastUpdatedBy: string = "";
  public countriesData: ILookupData[] = [];
  public statesData: ILookupData[] = [];
  currenciesData: ICurrencyData[] = [];

  constructor(
    private fb: FormBuilder,
    private loggedUserService: LoggedUserService,
    private agenciesAndInspectorsDataService: AgenciesAndInspectorsDataService,
    private currencyDataService: CurrenciesDataService,
    private countriesAndStatesService: CountriesAndStatesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadCountries();
    this.loadCurrencies();

    this.addForm = this.fb.group({
      agencyName: ["", Validators.required],
      address: [""],
      country: ["countryDefault", Validators.required],
      city: [""],
      state: ["stateDefault", Validators.required],
      zip: [""],
      contact: [""],
      taxId: [""],
      phone: [""],
      rateLetterDate: [null],
      fax: [""],
      nsCertDate: [null],
      email: [""],
      currency: ["currencyDefault", Validators.required],
      inspectionRate: [null],
      travelRate: [null],
      reportRate: [null],
      mileageRate: [null],
      overtimeRate: [null],
      contract: [""],
      minority: [false],
      preferred: [false],
      active: [false],
    });
  }

  loadCountries() {
    this.countriesAndStatesService.getAllCountries().subscribe((data) => {
      this.countriesData = data;

      this.loadStates("countryDefault");
    });
  }

  loadStates(selectedCountryName: string) {
    let countryId: string;

    if (selectedCountryName === "countryDefault") {
      countryId = "118";
    } else {
      const selectedCountry: ILookupData =
        this.getCountryByName(selectedCountryName);
      countryId = selectedCountry.lookupId.toString();
    }

    this.countriesAndStatesService
      .getStateByCountryId(countryId)
      .subscribe((data) => {
        this.statesData = data;
      });
  }

  onCountryChange(selectedCountryName: string) {
    this.loadStates(selectedCountryName);
  }

  getCountryByName(name: string): ILookupData | null {
    var country = this.countriesData.find((country) => country.name === name);

    if (country === undefined) return null;
    else return country;
  }

  getStateByName(name: string): ILookupData | undefined {
    return this.statesData.find((state) => state.name === name);
  }

  loadCurrencies() {
    this.currencyDataService.getCurrencies().subscribe((data) => {
      this.currenciesData = data;
    });
  }

  backToAgenciesView(): void {
    this.router.navigate(["/qa-management/agencies-and-inspectors"]);
  }

  submitForm(): void {
    let messagesValidationsList: string[] = [];
    let formAgencyName: string = this.addForm.get("agencyName").value;
    let formAddress: string = this.addForm.get("address").value;
    let formCountry: string = this.addForm.get("country").value;
    let formCity: string = this.addForm.get("city").value;
    let formState: string = this.addForm.get("state").value;
    let formZip: string = this.addForm.get("zip").value;
    let formContact: string = this.addForm.get("contact").value;
    let formTaxId: string = this.addForm.get("taxId").value;
    let formPhone: string = this.addForm.get("phone").value;
    let formRateLetterDate: Date = this.addForm.get("rateLetterDate").value;
    let formFax: string = this.addForm.get("fax").value;
    let formNsCertDate: Date = this.addForm.get("nsCertDate").value;
    let formEmail: string = this.addForm.get("email").value;
    let formCurrency: string = this.addForm.get("currency").value;
    let formInspectionRate: number = this.addForm.get("inspectionRate").value;
    let formTravelRate: number = this.addForm.get("travelRate").value;
    let formReportRate: number = this.addForm.get("reportRate").value;
    let formMileageRate: number = this.addForm.get("mileageRate").value;
    let formOvertimeRate: number = this.addForm.get("overtimeRate").value;
    let formContract: string = this.addForm.get("contract").value;
    let formMinority: boolean = this.addForm.get("minority").value;
    let formPreferred: boolean = this.addForm.get("preferred").value;
    let formActive: boolean = this.addForm.get("active").value;

    if (formAgencyName === "")
      messagesValidationsList.push("Agency Name is Required.");
    if (formInspectionRate == null)
      messagesValidationsList.push("Inspection Rate is Required.");
    if (formTravelRate == null)
      messagesValidationsList.push("Travel Rate is Required.");
    if (formReportRate == null)
      messagesValidationsList.push("Coordination Rate is Required.");
    if (formMileageRate == null)
      messagesValidationsList.push("Mileage Rate is Required.");
    if (formOvertimeRate == null)
      messagesValidationsList.push("Overtime Rate is Required.");
    if (formCurrency === "currencyDefault")
      messagesValidationsList.push("Currency is Required.");

    this.validationMessages = messagesValidationsList;
    if (this.addForm.valid && this.validationMessages.length === 0) {
      let country: ILookupData | undefined = this.getCountryByName(formCountry);
      let state: ILookupData | undefined = this.getStateByName(formState);

      this.agencyData = {
        name: formAgencyName,
        address: formAddress,
        countryId: country?.lookupId,
        city: formCity,
        stateId: state?.lookupId,
        postalCode: formZip,
        contact: formContact,
        taxId: formTaxId,
        phone: formPhone,
        rateLetterDate: formRateLetterDate,
        nsCertDate: formNsCertDate,
        fax: formFax,
        email: formEmail,
        inspectionRate: formInspectionRate,
        travelRate: formTravelRate,
        reportRate: formReportRate,
        mileageRate: formMileageRate,
        overtimeRate: formOvertimeRate,
        currency: formCurrency,
        contract: formContract,
        minority: formMinority,
        preferred: formPreferred,
        active: formActive,
        updatedOn: new Date(),
        insertedOn: new Date(),
        updatedBy: "",
        insertedBy: "",
      };

      this.loggedUserService
        .getLoggedUserData()
        .subscribe((loggedUser: ILoggedUserData | null) => {
          if (loggedUser) {
            this.agencyData.updatedBy = loggedUser.userId.toString();
            this.agencyData.insertedBy = loggedUser.userId.toString();
            this.agenciesAndInspectorsDataService
              .addAgency(this.agencyData)
              .subscribe({
                next: () => {
                  this.router.navigate([
                    "/qa-management/agencies-and-inspectors",
                  ]);
                },
                error: (error) => {
                  console.error(error);
                },
              });
          } else
            console.error("Cannot fetch logged user data in add-agency form.");
        });
    }
  }
}

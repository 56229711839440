import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";

@Component({
  selector: "vendor-information-component",
  templateUrl: "./vendor-information.component.html",
})
export class VendorInformationComponent {
  title = "Vendor Information";
  subtitle = "Home Page";
  links: any[] = [];

  constructor(private http: HttpClient) {}
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Sort } from "@angular/material/sort";
import { TableSortService } from "projects/aqua3/src/app/services/table-sort.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { IWorkRequestViewData } from "../../interfaces/qa-management/work-requests/workRequestViewData";
import { ILookupData } from "../../interfaces/administration/lookupData";
import { IAgencyData } from "../../interfaces/qa-management/agencyData";
import { ICapitalProjectData } from "../../interfaces/administration/capitalProjectData";
import { AgenciesAndInspectorsDataService } from "../../services/qa-management/agencies-inspectors.service";
import { CountriesAndStatesService } from "../../services/countries-states.service";
import { CapitalProjectsDataService } from "../../services/capital-projects.service";
import { QaqcbuyerEngineerQaeService } from "../../services/qaqc-buyer-engineer-qae.service";
import { BusOpcosDataService } from "../../services/administration/bus-opcos-data-service";
import { IBuyerEngineerQaeDetailViewData } from "../../interfaces/qa-management/buyerEngineerQaeDetailViewData";
import { IInspectorViewData } from "../../interfaces/qa-management/inspectorViewData";
import { WorkRequestsDataService } from "../../services/qa-management/work-requests.service";

@Component({
  selector: "work-requests-component",
  templateUrl: "./work-requests-component.html",
  styleUrls: ["./work-requests-component.css"],
})
export class WorkRequestsComponent implements OnInit {
  title = "Work Requests";
  dataSource: MatTableDataSource<IWorkRequestViewData>;
  originalDataSource: MatTableDataSource<IWorkRequestViewData>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  listingsForm: FormGroup = this.fb.group({});

  public countriesData: ILookupData[] = [];
  public businessUnitsData: ILookupData[] = [];
  public agenciesData: IAgencyData[] = [];
  public capitalProjectsData: ICapitalProjectData[] = [];
  public qaesData: IBuyerEngineerQaeDetailViewData[] = [];
  public inspectorsData: IInspectorViewData[] = [];
  listingsVisible: boolean = false;

  statusMapping = [
    { id: 1, name: "Assigned" },
    { id: 2, name: "Canceled" },
    { id: 3, name: "Final" },
    { id: 4, name: "Inactive" },
    { id: 5, name: "Unassigned" },
  ];

  displayedColumns = [
    "iContractNo",
    "statusId",
    "qae",
    "originalIssuanceDate",
    "poRequiredDate",
    "currentBudget",
    "agencySRNumber",
    "agency",
    "inspector",
    "coordinator",
    "manufacturer",
    "commodity",
    "poNumber",
    "poValue",
    "vendorReference",
    "etcChargeCode",
    "originalBudget",
    "currentIssuanceDate",
    "description",
    "responsibleEngineer",
    "opco",
    "businessUnit",
    "clientProject",
    "finalCosts",
    "closeoutDate",
  ];

  dataSourceLoading: boolean = true;

  constructor(
    private tableSortService: TableSortService,
    private agenciesAndInspectorsDataService: AgenciesAndInspectorsDataService,
    private fb: FormBuilder,
    private countriesAndStatesService: CountriesAndStatesService,
    private capitalProjectsService: CapitalProjectsDataService,
    private buyerEngineerQaeService: QaqcbuyerEngineerQaeService,
    private busOpcosDataService: BusOpcosDataService,
    private workRequestsService: WorkRequestsDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadCountries();
    this.loadAgencies();
    this.loadBusinessUnits();
    this.loadCapitalProjects();
    this.loadBuyerEngineerQaes();
    this.loadInspectors();

    let formGroupConfig = {
      poNumber: [""],
      agencySRnumber: [""],
      etcChargeCode: [""],
      status: ["statusDefault", Validators.required],
      businessUnit: ["businessUnitDefault", Validators.required],
      capitalProject: ["capitalProjectDefault", Validators.required],
      agency: ["agencyDefault", Validators.required],
      qae: ["qaeDefault", Validators.required],
      inspector: ["inspectorDefault", Validators.required],
      iContractNo: [null],
      manufacturer: ["manufacturerDefault", Validators.required],
      country: ["countryDefault", Validators.required],
      description: [""],
      city: [""],
      criticality: ["criticalityDefault", Validators.required],
    };

    this.listingsForm = this.fb.group(formGroupConfig);
  }

  getCountryById(id: number): ILookupData | null {
    var country = this.countriesData.find((country) => country.lookupId === id);

    if (country === undefined) return null;
    else return country;
  }

  getBusinessUnitNameById(id: number): string | null {
    const businessUnit = this.businessUnitsData.find(
      (unit) => unit.lookupId === id
    );

    if (businessUnit === undefined) return null;
    else return businessUnit.name;
  }

  getStatusNameById(id: number): string {
    const status = this.statusMapping.find((status) => status.id === id);

    if (status === undefined) return "-";
    else return status.name;
  }

  getBUById(id: number): ILookupData | null {
    var bu = this.businessUnitsData.find((bu) => bu.lookupId === id);

    if (bu === undefined) return null;
    else return bu;
  }

  getAgencyById(id: number): IAgencyData | undefined {
    var agency = this.agenciesData.find((agency) => agency.agencyId === id);

    if (agency === undefined) return null;
    else return agency;
  }

  getInspectorById(id: number): IInspectorViewData | undefined {
    var inspector = this.inspectorsData.find(
      (inspector) => inspector.inspectorId === id
    );

    if (inspector === undefined) return null;
    else return inspector;
  }

  getCapitalProjectNameById(id: number): string | null {
    const capitalProject = this.capitalProjectsData.find(
      (project) => project.projectId === id
    );

    if (capitalProject === undefined) return null;
    else return capitalProject.longName;
  }

  getCapitalProjectById(id: number): ICapitalProjectData | undefined {
    return this.capitalProjectsData.find(
      (capitalProject) => capitalProject.projectId === id
    );
  }

  loadCountries() {
    this.countriesAndStatesService.getAllCountries().subscribe((data) => {
      this.countriesData = data;
    });
  }

  loadBusinessUnits() {
    this.busOpcosDataService.getActiveBUs().subscribe((data) => {
      this.businessUnitsData = data;
    });
  }

  loadCapitalProjects() {
    this.capitalProjectsService.getAllProjects().subscribe((data) => {
      this.capitalProjectsData = data;
    });
  }

  loadBuyerEngineerQaes() {
    this.buyerEngineerQaeService.getActiveQAEs().subscribe((data) => {
      this.qaesData = data;
    });
  }

  loadAgencies() {
    this.agenciesAndInspectorsDataService.getAllAgencies().subscribe((data) => {
      this.agenciesData = data;
    });
  }

  loadInspectors() {
    this.agenciesAndInspectorsDataService
      .getAllInspectors()
      .subscribe((data) => {
        this.inspectorsData = data;
      });
  }

  announceSortChange(event: Sort) {
    this.dataSource.data = this.tableSortService.sortDataSource(
      this.originalDataSource.data,
      event
    );
  }

  updateListings() {
    this.listingsVisible = true;

    let formDescription: string = this.listingsForm.get("description").value;
    let formEtcChargeCode: string =
      this.listingsForm.get("etcChargeCode").value;
    let formCity: string = this.listingsForm.get("city").value;
    let formPONumber: string = this.listingsForm.get("poNumber").value;
    let formSAPSO: string = this.listingsForm.get("agencySRnumber").value;

    let formIContractNo: number = Number(
      this.listingsForm.get("iContractNo").value
    );

    let countryIdValue = this.listingsForm.get("country").value;
    let formCountryId: number = isNaN(Number(countryIdValue))
      ? 0
      : Number(countryIdValue);

    let agencyIdValue = this.listingsForm.get("agency").value;
    let formAgencyId: number = isNaN(Number(agencyIdValue))
      ? 0
      : Number(agencyIdValue);

    let inspectorIdValue = this.listingsForm.get("inspector").value;
    let formInspectorId: number = isNaN(Number(inspectorIdValue))
      ? 0
      : Number(inspectorIdValue);

    let manufacturerIdValue = this.listingsForm.get("manufacturer").value;
    let formManufacturerId: number = isNaN(Number(manufacturerIdValue))
      ? 0
      : Number(manufacturerIdValue);

    let qaeIdValue = this.listingsForm.get("qae").value;
    let formQaeId: number = isNaN(Number(qaeIdValue)) ? 0 : Number(qaeIdValue);

    let statusIdValue = this.listingsForm.get("status").value;
    let formStatusId: number = isNaN(Number(statusIdValue))
      ? 0
      : Number(statusIdValue);

    let businessUnitIdValue = this.listingsForm.get("businessUnit").value;
    let formBusinessUnitId: number = isNaN(Number(businessUnitIdValue))
      ? 0
      : Number(businessUnitIdValue);

    let capitalProjectIdValue = this.listingsForm.get("capitalProject").value;
    let formCapitalProjectId: number = isNaN(Number(capitalProjectIdValue))
      ? 0
      : Number(capitalProjectIdValue);

    let criticalityIdValue = this.listingsForm.get("criticality").value;
    let formCriticalityId: number = isNaN(Number(criticalityIdValue))
      ? 0
      : Number(criticalityIdValue);

    this.workRequestsService
      .getWorkRequests(
        formCountryId,
        formAgencyId,
        formInspectorId,
        formManufacturerId,
        formQaeId,
        formStatusId,
        formDescription,
        formEtcChargeCode,
        formCity,
        formPONumber,
        formSAPSO,
        formBusinessUnitId,
        formCapitalProjectId,
        formIContractNo,
        formCriticalityId
      )
      .subscribe({
        next: (data) => {
          this.dataSourceLoading = true;

          this.originalDataSource =
            new MatTableDataSource<IWorkRequestViewData>(data);
          this.dataSource = new MatTableDataSource<IWorkRequestViewData>(
            this.originalDataSource.data
          );

          this.dataSourceLoading = false;
          // Delay the initialization of the paginator until the data is assigned
          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
            this.paginator.pageSize = 10;
          });
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  clearFilters() {
    // var countryFormControl = this.listingsForm.get("country") as FormControl;
    // countryFormControl.setValue("countryDefault");
    // var stateFormControl = this.listingsForm.get("state") as FormControl;
    // stateFormControl.setValue("stateDefault");
    // var agencyFormControl = this.listingsForm.get("agency") as FormControl;
    // agencyFormControl.setValue("agencyDefault");
    // var inspectorNameFormControl = this.listingsForm.get(
    //   "inspectorName"
    // ) as FormControl;
    // inspectorNameFormControl.setValue("");
    // var cityFormControl = this.listingsForm.get("city") as FormControl;
    // cityFormControl.setValue("");
  }

  exportResultsToExcel() {}

  addNewWorkRequest() {}

  // showInspectorDetails(inspectorViewData: IInspectorViewData) {
  //   this.router.navigate([
  //     "qa-management/agencies-and-inspectors/edit-inspector",
  //     inspectorViewData.inspectorId,
  //   ]);
  // }

  // addNewInspector() {
  //   this.router.navigate([
  //     "qa-management/agencies-and-inspectors/add-inspector",
  //   ]);
  // }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/approved-vendor-list/preview-project.component.ts"],"names":[],"mappings":";MACM;QACE,wBAAwB;QACxB,yCAAyC;MAC3C","sourcesContent":["\n      .button-container {\n        display: flex !important;\n        justify-content: space-between !important;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

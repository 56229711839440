import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Sort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { ICommodityRequestData } from "projects/aqua3/src/app/interfaces/administration/commodityRequestData";
import { TableSortService } from "projects/aqua3/src/app/services/table-sort.service";
import { CommodityRequestsDataService } from "projects/aqua3/src/app/services/administration/commodity-requests.service";
import { AdministrationLoadingDataService } from "projects/aqua3/src/app/services/administration/administration-loading-data-service";
import { MatPaginator } from "@angular/material/paginator";

@Component({
  selector: "pending-commodity-requests-component",
  templateUrl: "./pending-commodity-requests-component.html",
  styleUrls: ["./pending-commodity-requests.component.css"],
})
export class PendingCommodityRequestsComponent implements OnInit {
  dataSource: MatTableDataSource<ICommodityRequestData>;
  originalDataSource: MatTableDataSource<ICommodityRequestData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = [
    "action",
    "commodityCodeDescriptionPath",
    "supplierVendorName",
    "manufacturerVendorName",
    "brandName",
    "requestorEmail",
    "approvalStatus",
  ];

  dataSourceLoading: boolean = true;

  constructor(
    private tableSortService: TableSortService,
    private commodityRequestsDataService: CommodityRequestsDataService,
    private administrationLoadingDataService: AdministrationLoadingDataService,
    private router: Router,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<ICommodityRequestData>();

    this.administrationLoadingDataService.loadPendingCommodityRequestsData$.subscribe(
      {
        next: () => {
          // This will be triggered whenever loadUserRequestsData signal is sent
          if (this.originalDataSource == null) this.loadCommodityRequests(); // Call the method to reload data
        },
        error: (err) => console.error(err),
      }
    );
  }

  announceSortChange(event: Sort) {
    this.dataSource.data = this.tableSortService.sortDataSource(
      this.originalDataSource.data,
      event
    );
  }

  performAction(commodity: ICommodityRequestData) {
    this.commodityRequestsDataService.performActionOnCommodityRequest(
      commodity
    );
    this.router.navigate([
      "/administration/adminHome/commodity-requests/performAction",
    ]);
  }

  loadCommodityRequests() {
    this.commodityRequestsDataService.getPendingCommodityRequests().subscribe({
      next: (data) => {
        this.dataSourceLoading = true;

        this.originalDataSource = new MatTableDataSource<ICommodityRequestData>(
          data
        );
        this.dataSource = new MatTableDataSource<ICommodityRequestData>(
          this.originalDataSource.data
        );

        this.dataSourceLoading = false;
        this.dataSource.paginator = this.paginator;
        this.paginator.pageSize = 10;
        this.changeDetectorRefs.detectChanges();
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Sort } from "@angular/material/sort";
import { TableSortService } from "projects/aqua3/src/app/services/table-sort.service";
import { MatPaginator } from "@angular/material/paginator";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { VendorDataService } from "../../services/vendor-data.service";
import { ICommoditySearchData } from "../../interfaces/vendor-information/ICommoditySearchData";
import { Observable } from "rxjs";
import { ILookupData } from "../../interfaces/administration/lookupData";
import { CountriesAndStatesService } from "../../services/countries-states.service";

@Component({
  selector: "vendor-search-component",
  templateUrl: "./vendor-search.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }

      .form-check-inline {
        display: inline-block;
        margin-right: 10px;
      }

      .form-group.row.align-items-center {
        display: flex;
        align-items: center;
      }
    `,
  ],
})
export class VendorSearchComponent implements OnInit {
  title = "Agencies";
  public dataSource: MatTableDataSource<ICommoditySearchData>;
  public originalDataSource: MatTableDataSource<ICommoditySearchData>;
  public filtersForm: FormGroup;
  public countries$: Observable<ILookupData[]>;
  public states$: Observable<ILookupData[]>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  public displayedColumns: string[] = [
    "supplier",
    "etcAmlSupplier",
    "imaSupplier",
    "supCr",
    "manufacturer",
    "etcAmlManufacturer",
    "imaManufacturer",
    "mfgCr",
    "brand",
    "commodityCode",
    "commodityCodeDescription",
  ];

  dataSourceLoading: boolean = true;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private service: VendorDataService,
    private countriesAndStatesService: CountriesAndStatesService
  ) {
    this.filtersForm = this.fb.group({
      vendorName: [""],
      city: [""],
      country: [""],
      stateProvince: [""],
      imaNumber: [""],
      commodityCode: [""],
      commodityDescription: [""],
    });
  }

  ngOnInit(): void {
    this.updateListings();

    // Load countries
    this.countries$ = this.countriesAndStatesService.getAllCountries();

    // Subscribe to country changes to load states
    this.filtersForm.get("country").valueChanges.subscribe((countryId) => {
      this.onCountryChange(countryId);
    });
  }

  onCountryChange(countryId: string): void {
    if (countryId) {
      this.states$ =
        this.countriesAndStatesService.getStateByCountryId(countryId);
    } else {
      this.states$ = null;
    }
  }

  updateListings() {
    this.dataSourceLoading = true;
    this.service.searchVendorCommodity().subscribe((data) => {
      this.dataSource = new MatTableDataSource(data);
      this.originalDataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSourceLoading = false;
    });
  }

  applyFilters() {
    const filters = this.filtersForm.value;

    this.dataSource.data = this.originalDataSource.data.filter((item) => {
      return (
        (!filters.vendorName ||
          item.supplierVendorName
            .toLowerCase()
            .includes(filters.vendorName.toLowerCase()) ||
          item.manufacturerVendorName
            .toLowerCase()
            .includes(filters.vendorName.toLowerCase())) &&
        (!filters.city ||
          item.supplierCity === filters.city ||
          item.manufacturerCity === filters.city) &&
        (!filters.country ||
          item.supplierCountryName === filters.country ||
          item.manufacturerCountryName === filters.country) &&
        (!filters.stateProvince ||
          item.supplierStateName === filters.stateProvince ||
          item.manufacturerStateName === filters.stateProvince) &&
        (!filters.imaNumber ||
          item.manufacturerIMANumber === filters.imaNumber ||
          item.supplierIMANumber === filters.imaNumber) &&
        (!filters.commodityCode ||
          item.commodityCodeTrimmed === filters.commodityCode) &&
        (!filters.commodityDescription ||
          item.commodityDescriptionPath === filters.commodityDescription)
      );
    });

    this.dataSource.paginator = this.paginator;
  }

  clearFilters() {
    this.filtersForm.reset({
      vendorStatus: false,
      country: "",
      stateProvince: "",
      tmaNumber: "",
      commodityCode: "",
      commodityDescription: "",
    });
    this.applyFilters();
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.red-text {
    color: red;
    font-weight: bold;
  }`, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/administration/administration-home-subcomponents/commodity-requests/rejected-commodity-requests/rejected-commodity-requests.component.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;EACnB","sourcesContent":[".red-text {\n    color: red;\n    font-weight: bold;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { NgModule } from "@angular/core";
import { MenuModule } from "../menu/menu.module";
import { CreateProjectComponent } from "./create-project.component";
import { ApprovedVendorListComponent } from "./approved-vendor-list.component";
import { EditProjectComponent } from "./edit-project.component";
import { PreviewProjectComponent } from "./preview-project.component";
import { AvlTabComponent } from "./preview-tabs/avl-tab.component";
import { ProjectMembersComponent } from "./preview-tabs/project-members.component";
import { SupplierAlertsComponent } from "./preview-tabs/supplier-alerts.component";
import { PerformanceTrackingComponent } from "./preview-tabs/performance-tracking.component";

const componentList = [
  ApprovedVendorListComponent,
  CreateProjectComponent,
  EditProjectComponent,
  PreviewProjectComponent,
  AvlTabComponent,
  ProjectMembersComponent,
  SupplierAlertsComponent,
  PerformanceTrackingComponent,
];

@NgModule({
  imports: [MenuModule],
  declarations: [componentList],
})
export class ApprovedVendorListModule {}

import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Sort } from "@angular/material/sort";
import { TableSortService } from "projects/aqua3/src/app/services/table-sort.service";
import {
  IAq2QaqcbuyerEngineerQae,
  IAq2QaqcbuyerEngineerQaeUpdatedByUserData,
} from "../../interfaces/qa-management/aq2QaqcbuyerEngineerQae";
import { MatPaginator } from "@angular/material/paginator";
import { QaqcbuyerEngineerQaeService } from "../../services/qaqc-buyer-engineer-qae.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoggedUserService } from "../../services/common/logged-user.service";
import { ILoggedUserData } from "../../interfaces/common/loggedUserData";
import { GraphService } from "../../services/administration/graph.service";
import { IUserData } from "../../interfaces/administration/userData";

@Component({
  selector: "buyers-engineers-qaes-edit-component",
  templateUrl: "./buyers-engineers-qaes-edit-component.html",
  styles: [
    `
      .form-check-inline {
        display: inline-block;
        margin-right: 10px;
      }

      .form-group.row.align-items-center {
        display: flex;
        align-items: center;
      }

      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `,
  ],
})
export class BuyersEngineersQaesEditComponent implements OnInit {
  public form: FormGroup;
  public isLoading = false;
  public showUpdatedBy = false;
  public validationMessages: string[] = [];
  public updateByUserData: IAq2QaqcbuyerEngineerQaeUpdatedByUserData;
  public userData: IUserData[];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private service: QaqcbuyerEngineerQaeService,
    private loggedUserService: LoggedUserService,
    private graphService: GraphService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.form = this.fb.group({
      id: [""],
      insertedBy: [""],
      insertedOn: [""],
      cai: [""],
      role: ["", Validators.required],
      lastName: ["", Validators.required],
      firstName: ["", Validators.required],
      middleInitial: ["", Validators.maxLength(1)],
      address: ["", Validators.required],
      phoneNumber: [""],
      faxNumber: [""],
      email: ["", [Validators.email]],
      active: [false],
    });

    this.service.currentBuyerEngineerQAEs$.subscribe((data) => {
      if (data) {
        if (data.updatedBy != "" && data.updatedBy != null) {
          this.graphService
            .searchForUserInEntra(data.updatedBy)
            .subscribe((user) => {
              if (user.length > 0) {
                this.userData = user;
                this.updateByUserData.cai = data.updatedBy;
                this.updateByUserData.updatedBy =
                  user[0].firstName + " " + user[0].lastName;
                this.updateByUserData.updatedOn = new Date(
                  data.updatedOn
                ).toLocaleString();
                this.showUpdatedBy = true;
              }
            });
        }

        this.form.patchValue({
          id: data.id,
          cai: data.cai,
          role: data.isBuyer ? "Buyer" : data.isEngineer ? "Engineer" : "QAE",
          lastName: data.lastName,
          firstName: data.firstName,
          middleInitial: data.middleInitial,
          address: data.address,
          phoneNumber: data.phone,
          faxNumber: data.fax,
          email: data.email,
          active: data.active,
          insertedBy: data.insertedBy,
          insertedOn: data.insertedOn,
        });
        this.isLoading = false;
      }
    });
  }

  onSubmit(): void {
    this.validationMessages = this.service.validateForm(this.form);
    if (this.validationMessages.length === 0) {
      this.isLoading = true;

      this.loggedUserService.getLoggedUserData().subscribe((data) => {
        const formValue = this.form.value;
        const buyer: IAq2QaqcbuyerEngineerQae = {
          id: formValue.id,
          lastName: formValue.lastName,
          firstName: formValue.firstName,
          middleInitial: formValue.middleInitial,
          cai: formValue.cai,
          address: formValue.address,
          phone: formValue.phoneNumber,
          fax: formValue.faxNumber,
          active: formValue.active,
          email: formValue.email,
          updatedBy: data ? data.cai : "",
          updatedOn: new Date().toISOString(),
          isBuyer: formValue.role === "Buyer",
          isEngineer: formValue.role === "Engineer",
          isQae: formValue.role === "QAE",
          insertedBy: formValue.insertedBy,
          insertedOn: formValue.insertedOn,
        };

        this.service.updateQaqcbuyerEngineerQae(buyer).subscribe((response) => {
          this.isLoading = false;
          this.router.navigate(["/qa-management/buyers-engineers-qaes"]);
        });
      });
    }
  }

  cancelRequest(): void {
    this.router.navigate(["/qa-management/buyers-engineers-qaes"]);
  }

  newEntry(): void {
    this.router.navigate(["/qa-management/buyers-engineers-qaes/create"]);
  }
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Sort } from "@angular/material/sort";
import { TableSortService } from "projects/aqua3/src/app/services/table-sort.service";
import { AgenciesAndInspectorsDataService } from "../../../services/qa-management/agencies-inspectors.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { CountriesAndStatesService } from "../../../services/countries-states.service";
import { ILookupData } from "../../../interfaces/administration/lookupData";
import { MatPaginator } from "@angular/material/paginator";
import { IAgencyViewData } from "../../../interfaces/qa-management/agencyViewData";
import { Router } from "@angular/router";

@Component({
  selector: "agencies-component",
  templateUrl: "./agencies-component.html",
})
export class AgenciesComponent implements OnInit {
  title = "Agencies";
  dataSource: MatTableDataSource<IAgencyViewData>;
  originalDataSource: MatTableDataSource<IAgencyViewData>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  listingsForm: FormGroup = this.fb.group({});

  public countriesData: ILookupData[] = [];
  public statesData: ILookupData[] = [];
  listingsVisible: boolean = false;

  displayedColumns = [
    "action",
    "name",
    "contact",
    "phone",
    "active",
    "preferred",
    "minority",
    "contract",
    "rateLetterDate",
  ];

  dataSourceLoading: boolean = true;

  constructor(
    private tableSortService: TableSortService,
    private agenciesAndInspectorsDataService: AgenciesAndInspectorsDataService,
    private fb: FormBuilder,
    private countriesAndStatesService: CountriesAndStatesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadCountries();

    let formGroupConfig = {
      agencyName: [""],
      country: ["countryDefault", Validators.required],
      state: ["stateDefault", Validators.required],
      city: [""],
    };

    this.listingsForm = this.fb.group(formGroupConfig);

    this.listingsForm.patchValue({
      country: "countryDefault",
    });
  }
  

  loadCachedAgencies() {
      this.originalDataSource = new MatTableDataSource<IAgencyViewData>(
        this.agenciesAndInspectorsDataService.cachedAgencies
      );
      this.dataSource = new MatTableDataSource<IAgencyViewData>(
        this.originalDataSource.data
      );
  }

  getCountryByName(name: string): ILookupData | null {
    var country = this.countriesData.find((country) => country.name === name);

    if (country === undefined) return null;
    else return country;
  }

  getStateByName(name: string): ILookupData | undefined {
    return this.statesData.find((state) => state.name === name);
  }

  loadCountries() {
    this.countriesAndStatesService.getAllCountries().subscribe((data) => {
      this.countriesData = data;

      this.loadStates("countryDefault");
    });
  }

  onCountryChange(selectedCountryName: string) {
    this.loadStates(selectedCountryName);
  }

  loadStates(selectedCountryName: string) {
    let countryId: string;

    if (selectedCountryName === "countryDefault") {
      countryId = "118";
    } else {
      const selectedCountry: ILookupData =
        this.getCountryByName(selectedCountryName);
      countryId = selectedCountry.lookupId.toString();
    }

    this.countriesAndStatesService
      .getStateByCountryId(countryId)
      .subscribe((data) => {
        this.statesData = data;
      });
  }

  announceSortChange(event: Sort) {
    this.dataSource.data = this.tableSortService.sortDataSource(
      this.originalDataSource.data,
      event
    );
  }

  updateListings() {
    this.listingsVisible = true;

    let formCountryName: string = this.listingsForm.get("country").value;
    let formStateName: string = this.listingsForm.get("state").value;
    let formAgencyName: string = this.listingsForm.get("agencyName").value;
    let formCity: string = this.listingsForm.get("city").value;

    const country: ILookupData = this.getCountryByName(formCountryName);
    const state: ILookupData = this.getStateByName(formStateName);

    this.agenciesAndInspectorsDataService
      .getAgencies(
        formAgencyName,
        formCity,
        country?.lookupId ?? 0,
        state?.lookupId ?? 0
      )
      .subscribe({
        next: (data) => {
          this.dataSourceLoading = true;

          this.originalDataSource = new MatTableDataSource<IAgencyViewData>(
            data
          );
          this.dataSource = new MatTableDataSource<IAgencyViewData>(
            this.originalDataSource.data
          );

          this.dataSourceLoading = false;
          // Delay the initialization of the paginator until the data is assigned
          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
            this.paginator.pageSize = 10;
          });
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  clearFilters() {
    var countryFormControl = this.listingsForm.get("country") as FormControl;
    countryFormControl.setValue("countryDefault");

    var stateFormControl = this.listingsForm.get("state") as FormControl;
    stateFormControl.setValue("stateDefault");

    var agencyNameFormControl = this.listingsForm.get(
      "agencyName"
    ) as FormControl;
    agencyNameFormControl.setValue("");

    var cityFormControl = this.listingsForm.get("city") as FormControl;
    cityFormControl.setValue("");
  }

  showAgencyDetails(agencyViewData: IAgencyViewData) {
    this.router.navigate([
      "qa-management/agencies-and-inspectors/edit-agency",
      agencyViewData.agencyId,
    ]);
  }

  addNewAgency() {
    this.router.navigate(["qa-management/agencies-and-inspectors/add-agency"]);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ILookupData } from 'projects/aqua3/src/app/interfaces/administration/lookupData';
import { LookupDataService } from '../../../services/administration/lookup-data-service';
import { Router } from '@angular/router';
import { environment } from "projects/aqua3/src/environments/environment";
import { BusOpcosDataService } from '../../../services/administration/bus-opcos-data-service';
const apiUrl = environment.apiUrl;

@Component({
  selector: 'edit-opco-data-form.component',
  templateUrl: './edit-opco-data-form.component.html',
})
export class EditOpcoDataFormComponent implements OnInit {
    data: ILookupData;
    editForm: FormGroup;
    validationMessages = [];

    constructor(
        private busOpcosDataService: BusOpcosDataService,
        private fb: FormBuilder,
        private lookupDataService: LookupDataService,
        private router: Router,
    ) {}
    ngOnInit(): void {
        this.lookupDataService.opcoData$.subscribe(data => {
            this.data = data;
            this.editForm = this.fb.group({
                opcoName: [this.data.name, Validators.required],
                opcoDesc: [this.data.description, Validators.required],
                opcoIsActive: [this.data.isActive, Validators.required],
            });
        });
    }

    submitForm(): void {
        let messagesValidationsList: string[] = [];
        let formOpcoName: string = this.editForm.get('opcoName').value;
        let formOpcoDesc: string = this.editForm.get('opcoDesc').value;

        if (formOpcoName === "")
            messagesValidationsList.push('Please enter the name of an Operating Company (OPCO).');

        if (formOpcoDesc === "")
            messagesValidationsList.push('Please enter a description of the Operating Company (OPCO).');

        this.validationMessages = messagesValidationsList;

        if (this.editForm.valid && this.validationMessages.length === 0) {
            this.data.name = formOpcoName;
            this.data.description = formOpcoDesc;
            this.data.isActive = this.editForm.get('opcoIsActive').value;

            this.busOpcosDataService.updateOpco(this.data).subscribe({
                next: () => {
                    this.router.navigate(['/administration/business-unit-opcos']);
                },
                error: (error) => {
                  console.error(error);
                },
              });
        }
    }

    cancel(): void {
        this.router.navigate(['/administration/business-unit-opcos']);  
    }
}
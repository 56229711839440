import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ICommodity } from '../../../interfaces/administration/commodity';
import { CommodityCodesService } from '../../../services/administration/commodity-codes.service';
import { CommodityViewMode } from '../../../interfaces/administration/commodityViewMode';
import { UserRequestsDataService } from '../../../services/administration/user-requests.service';
import { IUserData } from '../../../interfaces/administration/userData';

@Component({
  selector: 'add-commodity',
  templateUrl: './add-commodity.component.html',
  styleUrls: ["../commodity-codes.component.css"],
})
export class AddCommodityComponent {
  @Input() parentId: number;
  public commoditiesData!: MatTableDataSource<ICommodity>;
  public smeUsersData!: IUserData[];
  public parentChoosenNode: ICommodity;
  commodityForm: FormGroup;
  commodityLevelForChild: number;
  addingCommodity = false;
  validationMessages = [];
  smeOptions = [];

  constructor(private commodityCodesService: CommodityCodesService, private userRequestService: UserRequestsDataService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.commodityForm = this.fb.group({
      commodityCodeLevel1: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(2)]],
      commodityCodeLevel2: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(2)]],
      commodityCodeLevel3: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(2)]],
      commodityCodeLevel4: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(2)]],
      description: ['', Validators.required],
      sme: ['', Validators.required]
    });

    if (this.parentId != 0) {
      this.commodityCodesService.choosenNode$.subscribe((node) => {
        this.parentChoosenNode = node;
        this.commodityLevelForChild = this.parentChoosenNode.commodityLevel + 1;
        this.commodityForm.setValue({
          commodityCodeLevel1: this.parentChoosenNode.commodityCodeLevel1,
          commodityCodeLevel2: this.parentChoosenNode.commodityCodeLevel2,
          commodityCodeLevel3: this.parentChoosenNode.commodityCodeLevel3,
          commodityCodeLevel4: this.parentChoosenNode.commodityCodeLevel4,
          description: "",
          sme: 0,
        });
      });
    } else {
      this.commodityLevelForChild = 1;
      this.commodityForm.setValue({
        commodityCodeLevel1: 0,
        commodityCodeLevel2: 0,
        commodityCodeLevel3: 0,
        commodityCodeLevel4: 0,
        description: "",
        sme: 0,
      });
    }

    this.userRequestService.getSMEUsers().subscribe({
      next: (data) => {
        this.smeUsersData = data;
        this.smeOptions = this.smeUsersData.map(user => ({
          id: user.userId,
          name: user.cai + ': ' + user.firstName + ' ' + user.lastName,
        }));

        this.smeOptions.unshift({ id: 0, name: '-- NO SME SELECTION --' });
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  changeViewMode(viewMode: CommodityViewMode): void {
    this.commodityCodesService.updateCommodityViewMode(viewMode);
  }

  addCommodity() {
    this.validationMessages = [];
    this.commodityForm.markAllAsTouched();

    var descriptionForm = this.commodityForm.get('description');

    if (descriptionForm.value == null || descriptionForm.value == '') {
      this.validationMessages.push('Description is required');
    }

    if (this.commodityForm.valid && this.validationMessages.length == 0) {
      var updatedCommodity: ICommodity = { ...this.parentChoosenNode };
      this.userRequestService.actualLoggedUserData$.subscribe(
        {
          next: (data) => {
            var userId = data.userId;

            var smeResult = null
            var smeId = this.commodityForm.get('sme').value;
            if (0 < smeId) {
              smeResult = smeId;
            }

            updatedCommodity = {
              ...updatedCommodity,
              commodityId: 0,
              commodityCodeParentId: this.parentId,
              commodityLevel: this.commodityLevelForChild,
              commodityCodeLevel1: this.commodityForm.get('commodityCodeLevel1').value,
              commodityCodeLevel2: this.commodityForm.get('commodityCodeLevel2').value,
              commodityCodeLevel3: this.commodityForm.get('commodityCodeLevel3').value,
              commodityCodeLevel4: this.commodityForm.get('commodityCodeLevel4').value,
              description: descriptionForm.value,
              approvedByUserId: userId,
              approverCai: data.cai,
              approverEmail: data.email,
              approverFirstName: data.firstName,
              approverLastName: data.lastName,
              approvedComments: "Added commodity to the source tree",
              approvedDate: new Date().toISOString(),
              isActive: true,
              isApproved: true,
              subjectMatterExpertUserId: smeResult,
            };

            this.addingCommodity = true;
            this.commodityCodesService.addCommodity(updatedCommodity).subscribe({
              next: (response) => {
                this.addingCommodity = false;
                this.commodityCodesService.sendChoosenNode(response);
                this.commodityCodesService.getAllCommodities().subscribe(() => {
                  this.cancelAddCommodity();
                });
              },
              error: (error) => {
                this.addingCommodity = false;
                this.validationMessages.push('Error adding commodity');
                console.error('Error adding commodity', error);
              }
            });
          }
        }
      );
    }
  }

  cancelAddCommodity() {
    this.changeViewMode(CommodityViewMode.Base);
  }

  smeCaiAndName(node: ICommodity): string {
    return node.smecai + ': ' + node.smefirstName + ' ' + node.smelastName;
  }
}
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { Observable } from "rxjs";
import { ICurrencyData } from "../../interfaces/qa-management/currencyData";
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class CurrenciesDataService {
  constructor(private http: HttpClient) {}

  public getCurrencies(): Observable<ICurrencyData[]> {
    return this.http.get<ICurrencyData[]>(`${apiUrl}/currencies`);
  }
}

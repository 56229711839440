import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { Observable, tap } from "rxjs";
import { IWorkRequestViewData } from "../../interfaces/qa-management/work-requests/workRequestViewData";
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class WorkRequestsDataService {
  constructor(private http: HttpClient) {}

  public getWorkRequests(
    countryId?: number,
    agencyId?: number,
    inspectorId?: number,
    manufacturerId?: number,
    qaeId?: number,
    statusId?: number,
    description?: string,
    etcChargeCode?: string,
    city?: string,
    poNumber?: string,
    sapso?: string,
    businessUnitId?: number,
    capitalProjectId?: number,
    iContractNo?: number,
    criticalityId?: number
  ): Observable<IWorkRequestViewData[]> {
    let params = new HttpParams();
    params = params.append("countryId", countryId);
    params = params.append("agencyId", agencyId);
    params = params.append("inspectorId", inspectorId);
    params = params.append("manufacturerId", manufacturerId);
    params = params.append("qaeId", qaeId);
    params = params.append("statusId", statusId);
    if (description !== undefined)
      params = params.append("description", description);
    if (etcChargeCode !== undefined)
      params = params.append("etcChargeCode", etcChargeCode);
    if (city !== undefined) params = params.append("city", city);
    if (poNumber !== undefined) params = params.append("poNumber", poNumber);
    if (sapso !== undefined) params = params.append("sapso", sapso);
    params = params.append("businessUnitId", businessUnitId);
    params = params.append("capitalProjectId", capitalProjectId);
    params = params.append("iContractNo", iContractNo);
    params = params.append("criticalityId", criticalityId);

    return this.http.get<IWorkRequestViewData[]>(`${apiUrl}/workRequests`, {
      params,
    });
  }
}

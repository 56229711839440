import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NextPageService } from '@cvx/nextpage';

@Component({
    selector: 'home',
    templateUrl: 'home.component.html',
    styleUrls: ["home.component.css"]
})

export class HomeComponent {
    title = "Home";
    subtitle = "";
    constructor(private NP: NextPageService, private activatedRoute: ActivatedRoute) {

    }
}
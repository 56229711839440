// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bordered-container {
    border: 1px solid #000; 
    padding: 10px; 
    border-radius: 5px; 
  }`, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/qa-management/work-requests/work-requests-component.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;EACpB","sourcesContent":[".bordered-container {\n    border: 1px solid #000; \n    padding: 10px; \n    border-radius: 5px; \n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

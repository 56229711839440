import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ILookupData } from "../../../interfaces/administration/lookupData";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CountriesAndStatesService } from "../../../services/countries-states.service";

@Component({
  selector: "app-create-state",
  templateUrl: "./create-state.component.html",
})
export class CreateStateComponent implements OnInit {
  data: ILookupData;
  createForm: FormGroup;
  validationMessages = [];
  currentCountry: ILookupData;

  constructor(
    private countriesAndStatesService: CountriesAndStatesService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.createForm = this.fb.group({
      stateName: ["", Validators.required],
    });

    this.countriesAndStatesService.currentCountryData$.subscribe((data) => {
      this.currentCountry = data;
    });
  }

  submitForm(): void {
    let messagesValidationsList: string[] = [];
    let formStateName: string = this.createForm.get('stateName').value;

    if (formStateName === "")
      messagesValidationsList.push('Please enter the name of the state.');

    this.validationMessages = messagesValidationsList;

    if (this.createForm.valid && this.validationMessages.length === 0) {
      this.data = {
        name: formStateName,
        description: "",
        parentLookupId: this.currentCountry.lookupId,
      };

      this.countriesAndStatesService.createState(this.data).subscribe({
        next: () => {
          this.backToCountriesAndStates();
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  cancel(): void {
    this.backToCountriesAndStates();
  }

  private backToCountriesAndStates() {
    this.router.navigate(["administration/countries-states"], { state: { currentCountry: this.currentCountry } });
  }
}

import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BusOpcosDataService } from "../../services/administration/bus-opcos-data-service";
import { PurchaserManagementService } from "../../services/administration/purchaser-management.service";
import { CapitalProjectsDataService } from "../../services/capital-projects.service";
import { ICapitalProjectUsersData } from "../../interfaces/administration/capitalProjectUsersData";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { TableSortService } from "../../services/table-sort.service";
import { Sort } from "@angular/material/sort";
import { UserRequestsDataService } from "../../services/administration/user-requests.service";
import { IUserData } from "../../interfaces/administration/userData";
import { GraphService } from "../../services/administration/graph.service";

@Component({
  selector: "performance-tracking-component",
  templateUrl: "./performance-tracking.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `,
  ],
})
export class PerformanceTrackingComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public title = "Create new project";
  public dataSource: MatTableDataSource<ICapitalProjectUsersData>;
  public originalDataSource: MatTableDataSource<ICapitalProjectUsersData>;
  public dataSourceLoading: boolean = false;
  public dataUserLoading: boolean = false;
  public displayedColumns: string[] = ["userId", "isProjectAdmin"];
  public users: IUserData[];
  public selectedUser: number = 0;

  constructor(
    private tableSortService: TableSortService,
    private capitalProjectsDataService: CapitalProjectsDataService,
    private changeDetectorRefs: ChangeDetectorRef,
    private graphService: GraphService
  ) {}

  loadProjectMembers() {
    this.capitalProjectsDataService.loadProjectMembers$.subscribe((members) => {
      this.dataSourceLoading = true;
      this.dataUserLoading = true;

      this.originalDataSource =
        new MatTableDataSource<ICapitalProjectUsersData>(members);
      this.dataSource = new MatTableDataSource<ICapitalProjectUsersData>(
        this.originalDataSource.data
      );

      this.graphService.getAllUsers().subscribe((users) => {
        this.users = users;
        this.dataUserLoading = false;
      });

      this.dataSourceLoading = false;
      this.dataSource.paginator = this.paginator;
      this.paginator.pageSize = 10;
      this.changeDetectorRefs.detectChanges();
    });
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<ICapitalProjectUsersData>();
    this.originalDataSource =
      new MatTableDataSource<ICapitalProjectUsersData>();

    this.loadProjectMembers();
  }

  addMember() {
    this.dataSourceLoading = true;
    this.dataUserLoading = true;

    this.capitalProjectsDataService.currentCapitaProject$.subscribe((data) => {
      if (data) {
        this.capitalProjectsDataService
          .addProjectMember(data.projectId, this.selectedUser)
          .subscribe((members) => {
            this.dataSource.data = members;
          });

        this.capitalProjectsDataService.loadMembers(data.projectId);
      }
    });
  }

  announceSortChange(event: Sort) {
    this.dataSource.data = this.tableSortService.sortDataSource(
      this.originalDataSource.data,
      event
    );
  }
}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Router } from '@angular/router';
import { ILookupData } from '../../../interfaces/administration/lookupData';
import { LookupDataService } from '../../../services/administration/lookup-data-service';

@Component({
    selector: 'opco-add-button-component',
    templateUrl: './opco-add-button.component.html'
})
export class AddOpcoButtonComponent implements ICellRendererAngularComp {
    public data: ILookupData;

    constructor(private router: Router, private lookupDataService: LookupDataService) {}

    agInit(params: ICellRendererParams): void {
        this.data = params.data as ILookupData;
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }

    addOpco() {
        this.router.navigate(['/add-opco-data-form']);
    }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ILookupData } from "../../interfaces/administration/lookupData";
import { IEditBUFormData } from "../../administration/bus-opcos/forms/IEditBUFormData";
import { HttpClient } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class LookupDataService {
  constructor(private http: HttpClient) {}

  private opcoDataSubject: BehaviorSubject<ILookupData> = new BehaviorSubject(
    null
  );
  opcoData$: Observable<ILookupData> = this.opcoDataSubject.asObservable();

  private buDataSubject: BehaviorSubject<IEditBUFormData> = new BehaviorSubject(
    null
  );
  buData$: Observable<IEditBUFormData> = this.buDataSubject.asObservable();

  private addBUDataSubject: BehaviorSubject<ILookupData> = new BehaviorSubject(
    null
  );
  addBUData$: Observable<ILookupData> = this.addBUDataSubject.asObservable();

  updateOpcoData(data: ILookupData): void {
    this.opcoDataSubject.next(data);
  }

  updateBUData(data: IEditBUFormData): void {
    this.buDataSubject.next(data);
  }

  addBUData(parentOpco: ILookupData): void {
    this.addBUDataSubject.next(parentOpco);
  }

  public getLookupNameById(lookupId: number): Observable<string> {
    return this.http.get<string>(`${apiUrl}/lookups/${lookupId}/name`);
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blue-text {
  color: blue;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/administration/administration-home-subcomponents/commodity-requests/pending-commodity-requests/pending-commodity-requests.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".blue-text {\n  color: blue;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

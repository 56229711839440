import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ILookupData } from 'projects/aqua3/src/app/interfaces/administration/lookupData';
import { LookupDataService } from '../../../services/administration/lookup-data-service';
import { Router } from '@angular/router';
import { environment } from "projects/aqua3/src/environments/environment";
import { BusOpcosDataService } from '../../../services/administration/bus-opcos-data-service';
const apiUrl = environment.apiUrl;

@Component({
  selector: 'edit-bu-data-form.component',
  templateUrl: './edit-bu-data-form.component.html',
})
export class EditBUDataFormComponent implements OnInit {
    bUnit: ILookupData;
    opcoParent: ILookupData;
    opcos: ILookupData[];
    editForm: FormGroup;
    validationMessages = [];

    constructor(
        private busOpcosDataService: BusOpcosDataService,
        private fb: FormBuilder,
        private lookupDataService: LookupDataService,
        private router: Router,
    ) {}
    ngOnInit(): void {
        this.lookupDataService.buData$.subscribe(data => {
            this.bUnit = data.bUnit; //first element of tab is bUnit
            this.opcoParent = data.parentOpco; //second element of tab is opcoParent
            this.opcos = data.opcos;

            this.editForm = this.fb.group({
                buName: [this.bUnit.name, Validators.required],
                buDesc: [this.bUnit.description, Validators.required],
                buIsActive: [this.bUnit.isActive, Validators.required],
                buParentOpcoName: [this.opcoParent.name, Validators.required]
            });
        });
    }

    submitForm(): void {
        let messagesValidationsList: string[] = [];
        let formBUName: string = this.editForm.get('buName').value;
        let formBUDesc: string = this.editForm.get('buDesc').value;

        if (formBUName === "")
            messagesValidationsList.push('Please enter the name of an Business Unit.');

        if (formBUDesc === "")
            messagesValidationsList.push('Please enter a description of the Business Unit.');

        this.validationMessages = messagesValidationsList;

        if (this.editForm.valid && this.validationMessages.length === 0) {
            this.bUnit.name = formBUName;
            this.bUnit.description = formBUDesc;
            this.bUnit.isActive = this.editForm.get('buIsActive').value;
            let parentOpcoName: string = this.editForm.get('buParentOpcoName').value;
            this.bUnit.parentLookupId = this.opcos.find(opco => opco.name === parentOpcoName).lookupId;

            this.busOpcosDataService.updateBU(this.bUnit).subscribe({
                next: () => {
                    this.router.navigate(['/administration/business-unit-opcos']);
                },
                error: (error) => {
                  console.error(error);
                },
              });
        }
    }   

    cancel(): void {
        this.router.navigate(['/administration/business-unit-opcos']);                   
    }
}
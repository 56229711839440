import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

@Component({
  selector: 'reports-forms-component',
  templateUrl: './reports-forms.component.html'
})
export class ReportsFormsComponent {
  title = "Reports & Forms";
  subtitle = "Home Page"
  links: any[] = [];

  constructor(private http: HttpClient) { }

}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IUserData } from "../../../interfaces/administration/userData";
import { GraphService } from "../../../services/administration/graph.service";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styles: [
    `
      fieldset {
        border: none !important;
      }
    `,
  ],
})
export class AddUserComponent implements OnInit {
  dataSource: MatTableDataSource<IUserData>;
  usersSearching = false;
  searchResultEmpty = false;
  beforeFirstSearching = true;

  constructor(
    private router: Router,
    private graphService: GraphService,
    private fb: FormBuilder,
  ) { }

  addForm: FormGroup;
  validationMessages = [];

  displayedColumns = [
    'actions',
    'cai',
    'surname',
    'givenName',
    'mail',
  ];

  ngOnInit() {
    this.addForm = this.fb.group({
      searchText: ["", Validators.required],
    });
  }

  submitForm(): void {
    this.dataSource = new MatTableDataSource<IUserData>([]);
    this.validationMessages = [];
    this.searchResultEmpty = false;
    let formSearchText: string = this.addForm.get('searchText').value;

    if (formSearchText != null && formSearchText == "") {
      this.validationMessages.push("Please fill search text box.");
    }

    if (this.addForm.valid && this.validationMessages.length == 0) {
      this.usersSearching = true;
      this.beforeFirstSearching = false;
      this.graphService.searchForUserInEntra(formSearchText).subscribe((data) => {
        this.dataSource = new MatTableDataSource<IUserData>(data);
        this.usersSearching = false;
        if (data.length == 0) {
          this.searchResultEmpty = true;
        }
      });
    }
  }

  addUser(userData: IUserData) {
    console.log('User added: ' + userData.firstName + ' ' + userData.lastName);
  }

  cancel(): void {
    this.backToUserAndRoles();
  }

  private backToUserAndRoles() {
    this.router.navigate(["administration/user-roles"]);
  }
}

import { Component, OnInit } from "@angular/core"; // Assuming the interface is in this file
import { IVendorData } from "../../interfaces/administration/vendorData";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { VendorDataService } from "../../services/vendor-data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CountriesAndStatesService } from "../../services/countries-states.service";
import { Observable } from "rxjs";
import { ILookupData } from "../../interfaces/administration/lookupData";

@Component({
  selector: "app-edit-vendor",
  templateUrl: "./edit-vendor.component.html",
  styles: [
    `
      fieldset {
        border: none !important;
      }
    `,
  ],
})
export class EditVendorComponent implements OnInit {
  public vendorData: IVendorData;
  public countries$: Observable<ILookupData[]>;
  public states$: Observable<ILookupData[]>;
  public vendorForm: FormGroup;
  public validationMessages: string[] = [];
  public vendorId: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private countriesAndStatesService: CountriesAndStatesService,
    private vendorDataService: VendorDataService
  ) {
    this.vendorForm = this.fb.group({
      vendorName: ["", Validators.required],
      vendorURL: [""],
      isSupplier: [false],
      isManufacturer: [false],
      isApprovedByETC: [false],
      isAllianceOnly: [false],
      isRML: [false],
      streetAddress: ["", Validators.required],
      countryId: ["", Validators.required],
      stateId: [{ value: "", disabled: true }],
      newState: [""],
      countyEquivlient: [""],
      city: ["", Validators.required],
      postalCode: [""],
      url: [""],
      contactName: [""],
      emailAddress: ["", [Validators.email]],
      phoneNumber: [""],
      comments: [""],
      fax: [""],
      deactivationComments: [""],
      stateName: [""],
      countryName: [""],
      isMinorityFirm: [false],
      imaNumber: [""],
      isActive: [false],
      lastAuditDate: [null],
      auditId: [0],
      oldSupplierId: [0],
      oldManufacturerId: [0],
      vendorId: [0],
    });
  }

  ngOnInit() {
    this.vendorId =
      this.route.snapshot.paramMap.get("id") || this.route.queryParams["id"];
    this.countries$ = this.countriesAndStatesService.getAllCountries();

    if (this.vendorId) {
      this.vendorDataService.getVendorById(this.vendorId).subscribe((data) => {
        if (data) {
          this.vendorData = data;
          this.vendorForm.patchValue(data);
          this.vendorForm.get("vendorId").setValue(this.vendorId);

          if (this.vendorData && this.vendorData.countryId) {
            this.onCountryChange(this.vendorData.countryId.toString());
          }

          if (this.vendorData && this.vendorData.stateId) {
            this.vendorForm.get("stateId").enable();
            this.vendorForm.patchValue({ stateId: this.vendorData.stateId });
          }
        }
      });
    } else {
      this.router.navigate(["administration/vendors"]);
    }
  }

  onCountryChange(countryId: string): void {
    const stateControl = this.vendorForm.get("stateId");
    if (countryId) {
      this.states$ =
        this.countriesAndStatesService.getStateByCountryId(countryId);
      stateControl.enable();
    } else {
      this.states$ = null;
      stateControl.disable();
    }
  }

  onSubmit() {
    const validationMessages = this.vendorDataService.validateForm(
      this.vendorForm
    );

    if (validationMessages.length === 0) {
      const formData = this.vendorForm.value as IVendorData;
      this.vendorDataService.changeVendorData(formData);
      this.router.navigate(["/administration/vendors/preview/", this.vendorId]);
    } else {
      this.validationMessages = validationMessages;
    }
  }
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AgenciesAndInspectorsDataService } from "../../../services/qa-management/agencies-inspectors.service";
import { IInspectorData } from "../../../interfaces/qa-management/inspectorData";
import { LookupDataService } from "../../../services/administration/lookup-data-service";
import { ILookupData } from "../../../interfaces/administration/lookupData";
import { IAgencyData } from "../../../interfaces/qa-management/agencyData";
import { CountriesAndStatesService } from "../../../services/countries-states.service";
import { LoggedUserService } from "../../../services/common/logged-user.service";
import { ILoggedUserData } from "../../../interfaces/common/loggedUserData";
import { IInspectorRatingData } from "../../../interfaces/qa-management/inspectorRatingData";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { TableSortService } from "../../../services/table-sort.service";
import { Sort } from "@angular/material/sort";
import { CurrenciesDataService } from "../../../services/qa-management/currencies.service";
import { ICurrencyData } from "../../../interfaces/qa-management/currencyData";
import { NavigationCachingService } from "../../../services/qa-management/navigationCaching.service";

@Component({
  selector: "edit-inspector-form.component",
  templateUrl: "./edit-inspector-form.component.html",
})
export class EditInspectorFormComponent implements OnInit {
  editForm: FormGroup;
  activeTab: string = "general"; // Default to the first tab
  validationMessages = [];
  inspectorData: IInspectorData;
  agencyName: string = "";
  countryName: string = "";
  stateName: string = "";
  lastUpdatedBy: string = "";
  formEditable: boolean = false;
  public countriesData: ILookupData[] = [];
  public statesData: ILookupData[] = [];
  public agenciesData: IAgencyData[] = [];
  currenciesData: ICurrencyData[] = [];

  displayedColumns = [
    "commodityCode+commodityDescription",
    "inspAvg",
    "qawRs",
    "lastQAWR",
  ];

  inspectorRatingsDataSource: MatTableDataSource<IInspectorRatingData>;
  originalInspectorRatingsDataSource: MatTableDataSource<IInspectorRatingData>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  inspectorRatingsDataLoading: boolean = true;

  constructor(
    private fb: FormBuilder,
    private loggedUserService: LoggedUserService,
    private agenciesAndInspectorsDataService: AgenciesAndInspectorsDataService,
    private currencyDataService: CurrenciesDataService,
    private lookupsDataService: LookupDataService,
    private countriesAndStatesService: CountriesAndStatesService,
    private navigationCachingService: NavigationCachingService,
    private tableSortService: TableSortService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loadCountries();
    this.loadAgencies();
    this.loadCurrencies();

    this.editForm = this.fb.group({
      lastName: [
        { value: "", disabled: !this.formEditable },
        Validators.required,
      ],
      firstName: [
        { value: "", disabled: !this.formEditable },
        Validators.required,
      ],
      middleInitial: [{ value: "", disabled: !this.formEditable }],
      agency: [
        { value: "agencyDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      address: [{ value: "", disabled: !this.formEditable }, ,],
      country: [
        { value: "countryDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      city: [{ value: "", disabled: !this.formEditable }],
      state: [
        { value: "stateDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      zip: [{ value: "", disabled: !this.formEditable }],
      phone: [{ value: "", disabled: !this.formEditable }],
      fax: [{ value: "", disabled: !this.formEditable }],
      cellPhone: [{ value: "", disabled: !this.formEditable }],
      pager: [{ value: "", disabled: !this.formEditable }],
      email: [{ value: "", disabled: !this.formEditable }],
      currency: [
        { value: "currencyDefault", disabled: !this.formEditable },
        Validators.required,
      ],
      inspectionRate: [{ value: null, disabled: !this.formEditable }],
      travelRate: [{ value: null, disabled: !this.formEditable }],
      reportRate: [{ value: null, disabled: !this.formEditable }],
      mileageRate: [{ value: null, disabled: !this.formEditable }],
      overtimeRate: [{ value: null, disabled: !this.formEditable }],
      recommended: [{ value: false, disabled: !this.formEditable }],
    });

    const id = this.route.snapshot.paramMap.get("id");
    if (id) {
      this.agenciesAndInspectorsDataService
        .getInspectorById(id)
        .subscribe((data) => {
          if (data) {
            this.inspectorData = data;
            this.fillTheReadableForm();
          }
        });
    } else {
      this.router.navigate(["/qa-management/agencies-and-inspectors"]);
    }
  }

  loadCountries() {
    this.countriesAndStatesService.getAllCountries().subscribe((data) => {
      this.countriesData = data;

      this.loadStates("countryDefault");
    });
  }

  loadStates(selectedCountryName: string) {
    let countryId: string;

    if (selectedCountryName === "countryDefault") {
      countryId = "118";
    } else {
      const selectedCountry: ILookupData =
        this.getCountryByName(selectedCountryName);
      countryId = selectedCountry.lookupId.toString();
    }

    this.countriesAndStatesService
      .getStateByCountryId(countryId)
      .subscribe((data) => {
        this.statesData = data;
      });
  }

  loadAgencies() {
    this.agenciesAndInspectorsDataService.getAllAgencies().subscribe((data) => {
      this.agenciesData = data;
    });
  }

  onCountryChange(selectedCountryName: string) {
    this.loadStates(selectedCountryName);
  }

  getCountryByName(name: string): ILookupData | null {
    var country = this.countriesData.find((country) => country.name === name);

    if (country === undefined) return null;
    else return country;
  }

  getStateByName(name: string): ILookupData | undefined {
    return this.statesData.find((state) => state.name === name);
  }

  getAgencyByName(name: string): IAgencyData | undefined {
    return this.agenciesData.find((agency) => agency.name === name);
  }

  loadCurrencies() {
    this.currencyDataService.getCurrencies().subscribe((data) => {
      this.currenciesData = data;
    });
  }

  fillTheReadableForm(): void {
    this.inspectorRatingsDataLoading = true;

    if (this.inspectorData.agencyId != null)
      this.agenciesAndInspectorsDataService
        .getAgencyNameById(this.inspectorData.agencyId)
        .subscribe((data) => {
          if (data) {
            this.agencyName = data;
          }
        });

    if (this.inspectorData.countryId != null)
      this.lookupsDataService
        .getLookupNameById(this.inspectorData.countryId)
        .subscribe((data) => {
          if (data) {
            this.countryName = data;
          }
        });

    if (this.inspectorData.stateId != null)
      this.lookupsDataService
        .getLookupNameById(this.inspectorData.stateId)
        .subscribe((data) => {
          if (data) {
            this.stateName = data;
          }
        });

    this.agenciesAndInspectorsDataService
      .getInspectorRatings(this.inspectorData.inspectorId)
      .subscribe((data) => {
        if (data) {
          this.originalInspectorRatingsDataSource =
            new MatTableDataSource<IInspectorRatingData>(data);
          this.inspectorRatingsDataSource =
            new MatTableDataSource<IInspectorRatingData>(
              this.originalInspectorRatingsDataSource.data
            );
        }

        this.inspectorRatingsDataLoading = false;
        // Delay the initialization of the paginator until the data is assigned
        setTimeout(() => {
          this.inspectorRatingsDataSource.paginator = this.paginator;
          this.paginator.pageSize = 10;
        });
      });

    let reformattedDate: string = this.reformatDate(
      this.inspectorData.updatedOn
    );
    this.lastUpdatedBy = `
    Last updated by [${this.inspectorData.updatedBy}] on
    ${reformattedDate};
  `;
  }

  announceSortChange(event: Sort) {
    this.inspectorRatingsDataSource.data = this.tableSortService.sortDataSource(
      this.originalInspectorRatingsDataSource.data,
      event
    );
  }

  reformatDate(date: Date): string {
    let dateTyped: Date = new Date(date);
    return dateTyped.toLocaleString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  }

  backToInspectorsView(): void {
    this.navigationCachingService.triggerLoadCachedInspectors();
    this.router.navigate(["/qa-management/agencies-and-inspectors"]);
  }

  toggleEdit(): void {
    this.formEditable = !this.formEditable;

    if (this.formEditable) {
      this.editForm.patchValue({
        lastName: this.inspectorData.lastName,
        firstName: this.inspectorData.firstName,
        middleInitial: this.inspectorData.middleInitial,
        agency: this.agencyName || "agencyDefault",
        address: this.inspectorData.address,
        country: this.countryName || "countryDefault",
        city: this.inspectorData.city,
        state: this.stateName || "stateDefault",
        zip: this.inspectorData.postalCode,
        phone: this.inspectorData.phone,
        fax: this.inspectorData.fax,
        cellPhone: this.inspectorData.cellphone,
        pager: this.inspectorData.pager,
        email: this.inspectorData.email,
        currency: "currencyDefault",
        inspectionRate: this.inspectorData.inspectionRate,
        travelRate: this.inspectorData.travelRate,
        reportRate: this.inspectorData.reportRate,
        mileageRate: this.inspectorData.mileageRate,
        overtimeRate: this.inspectorData.overtimeRate,
        recommended: this.inspectorData.recommended,
      });

      this.editForm.get("lastName").enable();
      this.editForm.get("firstName").enable();
      this.editForm.get("middleInitial").enable();
      this.editForm.get("agency").enable();
      this.editForm.get("address").enable();
      this.editForm.get("country").enable();
      this.editForm.get("city").enable();
      this.editForm.get("state").enable();
      this.editForm.get("zip").enable();
      this.editForm.get("phone").enable();
      this.editForm.get("fax").enable();
      this.editForm.get("cellPhone").enable();
      this.editForm.get("pager").enable();
      this.editForm.get("email").enable();
      this.editForm.get("currency").enable();
      this.editForm.get("inspectionRate").enable();
      this.editForm.get("travelRate").enable();
      this.editForm.get("reportRate").enable();
      this.editForm.get("mileageRate").enable();
      this.editForm.get("overtimeRate").enable();
      this.editForm.get("recommended").enable();
    }
  }

  submitForm(): void {
    let messagesValidationsList: string[] = [];
    let formLastName: string = this.editForm.get("lastName").value;
    let formFirstName: string = this.editForm.get("firstName").value;
    let formAgencyName: string = this.editForm.get("agency").value;
    let formInspectionRate: number = this.editForm.get("inspectionRate").value;
    let formTravelRate: number = this.editForm.get("travelRate").value;
    let formReportRate: number = this.editForm.get("reportRate").value;
    let formMileageRate: number = this.editForm.get("mileageRate").value;
    let formOvertimeRate: number = this.editForm.get("overtimeRate").value;
    let formCurrency: string = this.editForm.get("currency").value;
    let formMiddleInitial: string = this.editForm.get("middleInitial").value;
    let formAddress: string = this.editForm.get("address").value;
    let formCountry: string = this.editForm.get("country").value;
    let formCity: string = this.editForm.get("city").value;
    let formState: string = this.editForm.get("state").value;
    let formZip: string = this.editForm.get("zip").value;
    let formPhone: string = this.editForm.get("phone").value;
    let formFax: string = this.editForm.get("fax").value;
    let formCellPhone: string = this.editForm.get("cellPhone").value;
    let formPager: string = this.editForm.get("pager").value;
    let formEmail: string = this.editForm.get("email").value;
    let formRecommended: boolean = this.editForm.get("recommended").value;

    if (formLastName === "")
      messagesValidationsList.push("Last Name is Required.");

    if (formFirstName === "")
      messagesValidationsList.push("First Name is Required.");

    let agency: IAgencyData | undefined = this.getAgencyByName(formAgencyName);
    if (agency == null) messagesValidationsList.push("Agency is Required.");

    if (
      formInspectionRate !== null ||
      formTravelRate !== null ||
      formReportRate !== null ||
      formMileageRate !== null ||
      formOvertimeRate !== null
    ) {
      if (formCurrency === "currencyDefault") {
        messagesValidationsList.push("Please select Currency.");
      }
    }

    this.validationMessages = messagesValidationsList;

    if (this.editForm.valid && this.validationMessages.length === 0) {
      let country: ILookupData | undefined = this.getCountryByName(formCountry);
      let state: ILookupData | undefined = this.getStateByName(formState);

      this.inspectorData.lastName = formLastName;
      this.inspectorData.firstName = formFirstName;
      this.inspectorData.middleInitial = formMiddleInitial;
      this.inspectorData.agencyId = agency?.agencyId;
      this.inspectorData.address = formAddress;
      this.inspectorData.countryId = country?.lookupId;
      this.inspectorData.city = formCity;
      this.inspectorData.stateId = state?.lookupId;
      this.inspectorData.postalCode = formZip;
      this.inspectorData.phone = formPhone;
      this.inspectorData.fax = formFax;
      this.inspectorData.cellphone = formCellPhone;
      this.inspectorData.pager = formPager;
      this.inspectorData.email = formEmail;
      this.inspectorData.inspectionRate = formInspectionRate;
      this.inspectorData.travelRate = formTravelRate;
      this.inspectorData.reportRate = formReportRate;
      this.inspectorData.mileageRate = formMileageRate;
      if (formCurrency !== "currencyDefault")
        this.inspectorData.currency = formCurrency;
      this.inspectorData.overtimeRate = formOvertimeRate;
      this.inspectorData.recommended = formRecommended;
      this.inspectorData.updatedOn = new Date();

      this.loggedUserService
        .getLoggedUserData()
        .subscribe((loggedUser: ILoggedUserData | null) => {
          if (loggedUser) {
            this.inspectorData.updatedBy = loggedUser.userId.toString();
            this.agenciesAndInspectorsDataService
              .updateInspector(this.inspectorData)
              .subscribe({
                next: () => {
                  this.router.navigate([
                    "/qa-management/agencies-and-inspectors",
                  ]);
                },
                error: (error) => {
                  console.error(error);
                },
              });
          } else
            console.error(
              "Cannot fetch logged user data in edit-inspector form."
            );
        });
    }
  }

  newEntry() {
    this.router.navigate([
      "/qa-management/agencies-and-inspectors/add-inspector",
    ]);
  }

  setActiveTab(tabName: string): void {
    this.activeTab = tabName;
  }
}

import { Component, Input, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { ILookupData } from "projects/aqua3/src/app/interfaces/administration/lookupData";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { UserRequestsDataService } from "../../../services/administration/user-requests.service";
import { IUserData } from "../../../interfaces/administration/userData";
const apiUrl = environment.apiUrl;

@Component({
  selector: "edit-user-request-form.component",
  templateUrl: "./edit-user-request-form.component.html",
})
export class EditUserRequestsFormComponent implements OnInit {
  user: IUserData;
  editForm: FormGroup;
  validationMessages = [];

  private radioButton_UserIsApproved: FormControl = new FormControl({
    value: "userApproved",
    disabled: false,
  });

  constructor(
    private userRequestDataService: UserRequestsDataService,
    private fb: FormBuilder,
    private router: Router
  ) { }
  ngOnInit(): void {
    this.userRequestDataService.editUserRequestsData$.subscribe((data) => {
      this.user = data;

      this.editForm = this.fb.group({
        userRequestedComments: [
          this.user.requestedComments,
          Validators.required,
        ],
        userApprovedComments: [this.user.approvedComments, Validators.required],
        userIsApproved: this.radioButton_UserIsApproved,
      });
    });
  }

  submitForm(): void {
    let messagesValidationsList: string[] = [];
    let formUserApprovedComments: string = this.editForm.get(
      "userApprovedComments"
    ).value;
    let formUserIsApproved: boolean = this.getUserIsApproved(
      this.editForm.get("userIsApproved").value
    );

    if (formUserApprovedComments === null || formUserApprovedComments === "")
      messagesValidationsList.push(
        "You must enter some comments about the approval"
      );

    this.validationMessages = messagesValidationsList;

    if (this.editForm.valid && this.validationMessages.length === 0) {
      this.user.isApproved = formUserIsApproved;
      this.user.isActive = formUserIsApproved;
      this.user.approvedByDate = new Date();
      this.user.approvedComments = formUserApprovedComments;
      this.userRequestDataService.performAction(this.user).subscribe({
        next: () => {
          this.router.navigate(["/administration/adminHome"]);
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  getUserIsApproved(formUserIsApproved: string): boolean {
    return formUserIsApproved === "userApproved";
  }

  cancel(): void {
    this.router.navigate(["/administration/adminHome"]);
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { IAgencyData } from "../../interfaces/qa-management/agencyData";
import { Observable, tap } from "rxjs";
import { IInspectorViewData } from "../../interfaces/qa-management/inspectorViewData";
import { IInspectorData } from "../../interfaces/qa-management/inspectorData";
import { IInspectorRatingData } from "../../interfaces/qa-management/inspectorRatingData";
import { IAgencyViewData } from "../../interfaces/qa-management/agencyViewData";
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class AgenciesAndInspectorsDataService {
  constructor(private http: HttpClient) {}

  public cachedAgencies: IAgencyViewData[];
  public cachedInspectors: IInspectorViewData[];

  public getAllAgencies(): Observable<IAgencyData[]> {
    return this.http.get<IAgencyData[]>(`${apiUrl}/agencies/all`);
  }

  public getAgencies(
    agencyName: string,
    city: string,
    countryId?: number,
    stateId?: number
  ): Observable<IAgencyViewData[]> {
    return this.http
      .get<IAgencyViewData[]>(
        `${apiUrl}/agencies?countryId=${countryId}&stateId=${stateId}&agencyName=${agencyName}&city=${city}`
      )
      .pipe(tap((agencies) => (this.cachedAgencies = agencies)));
  }

  public getInspectors(
    inspectorName: string,
    city: string,
    countryId?: number,
    stateId?: number,
    agencyId?: number
  ): Observable<IInspectorViewData[]> {
    return this.http
      .get<IInspectorViewData[]>(
        `${apiUrl}/inspectors?countryId=${countryId}&stateId=${stateId}&agencyId=${agencyId}&inspectorName=${inspectorName}&city=${city}`
      )
      .pipe(tap((inspectors) => (this.cachedInspectors = inspectors)));
  }

  public getAllInspectors(): Observable<IInspectorViewData[]> {
    return this.http.get<IInspectorViewData[]>(`${apiUrl}/inspectors/all`);
  }

  public getInspectorById(inspectorId: string): Observable<IInspectorData> {
    return this.http.get<IInspectorData>(`${apiUrl}/inspectors/${inspectorId}`);
  }

  public getAgencyById(agencyId: string): Observable<IAgencyData> {
    return this.http.get<IAgencyData>(`${apiUrl}/agencies/${agencyId}`);
  }

  public getAgencyNameById(agencyId: number): Observable<string> {
    return this.http.get<string>(`${apiUrl}/agencies/${agencyId}/name`);
  }

  public updateAgency(agencyData: IAgencyData): Observable<any> {
    return this.http.put<IAgencyData>(`${apiUrl}/agencies`, agencyData);
  }

  public addAgency(agencyData: IAgencyData): Observable<any> {
    return this.http.post<IAgencyData>(`${apiUrl}/agencies`, agencyData);
  }

  public updateInspector(inspectorData: IInspectorData): Observable<any> {
    return this.http.put<IInspectorData>(`${apiUrl}/inspectors`, inspectorData);
  }

  public addInspector(inspectorData: IInspectorData): Observable<any> {
    return this.http.post<IInspectorData>(
      `${apiUrl}/inspectors`,
      inspectorData
    );
  }

  public getInspectorRatings(
    inspectorId: number
  ): Observable<IInspectorRatingData[]> {
    return this.http.get<IInspectorRatingData[]>(
      `${apiUrl}/inspectors/${inspectorId}/ratings`
    );
  }
}

import { Component, OnInit } from "@angular/core";
import { ILookupData } from "../../interfaces/administration/lookupData";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PurchaserManagementService } from "../../services/administration/purchaser-management.service";

@Component({
  selector: "app-create-purchaser-management",
  templateUrl: "./edit-purchaser-management.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `,
  ],
})
export class EditPurchaserManagementComponent implements OnInit {
  title = "Create new purchaser management";
  public purchaserData: ILookupData;
  public purchaseerId: string;
  public form: FormGroup;
  public validationMessages = [];
  public isLoading = true;

  constructor(
    private service: PurchaserManagementService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.purchaserData = {
      lookupId: 0,
      name: "",
      description: "",
      groupName: "EngineeringProcurmentContractor",
      groupOrder: 0,
      parentLookupId: 0,
      isActive: true,
    };

    // Initialize the form with empty values
    this.form = this.fb.group({
      purchaserName: ["", Validators.required],
      purchaserInfo: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.purchaseerId = this.route.snapshot.paramMap.get("id");
    this.service.getPurchaserById(this.purchaseerId).subscribe((data) => {
      if (data) {
        this.purchaserData = data;

        this.form = this.fb.group({
          purchaserName: [this.purchaserData.name, Validators.required],
          purchaserInfo: [this.purchaserData.description, Validators.required],
        });
      }
      this.isLoading = false;
    });
  }

  onSubmit() {
    this.validationMessages = this.service.validationResult(this.form);

    if (this.form.valid && this.validationMessages.length === 0) {
      this.isLoading = true;
      this.purchaserData.name = this.form.get("purchaserName").value;
      this.purchaserData.description = this.form.get("purchaserInfo").value;

      this.service
        .updatePurchaser(this.purchaseerId, this.purchaserData)
        .subscribe((data) => {
          if (data) {
            this.isLoading = false;
            this.router.navigate(["/administration/purchaser-management"]);
          }
        });
    }
  }

  cancelRequest() {
    this.router.navigate(["/administration/purchaser-management"]);
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ILookupData } from "../../interfaces/administration/lookupData";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { PurchaserManagementService } from "../../services/administration/purchaser-management.service";

@Component({
  selector: "app-purchaser-management",
  templateUrl: "./purchaser-management.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `,
  ],
})
export class PurchaserManagementComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public purchaserData: MatTableDataSource<ILookupData>;
  public displayedColumns: string[] = ["name", "description", "actions"];
  public isLoading = true;

  constructor(
    private service: PurchaserManagementService,
    private router: Router,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loadPurchasers();
    this.paginator.pageSize = 10;
    this.sort.active = "name";
    this.sort.direction = "asc";
    this.sort.sortChange.emit({
      active: this.sort.active,
      direction: this.sort.direction,
    });
  }

  ngAfterViewInit() {}

  loadPurchasers() {
    this.isLoading = true;
    this.service.getPurchasers().subscribe((data) => {
      this.purchaserData = new MatTableDataSource(data);
      this.purchaserData.sort = this.sort;
      this.purchaserData.paginator = this.paginator;
      this.paginator.pageSize = 10;
      this.isLoading = false;
      this.changeDetectorRefs.detectChanges();
    });
  }

  editPurchaser(purchaser: ILookupData) {
    this.router.navigate([
      "/administration/purchaser-management/edit",
      purchaser.lookupId,
    ]);
  }

  confirmDelete(purchaser) {
    if (window.confirm("Are you sure you want to delete this purchaser?")) {
      this.deletePurchaser(purchaser);
    }
  }

  deletePurchaser(purchaser: ILookupData) {
    this.isLoading = true;
    this.service
      .deletePurchaser(purchaser.lookupId.toString())
      .subscribe((result) => {
        this.loadPurchasers();
      });
  }
}

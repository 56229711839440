import { Component, Input, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { IAuditObservationDetail } from "../../../interfaces/qa-management/auditObservationDetailData";
import { RatingType } from "../manufacturer-audit-and-observation.component";

@Component({
    selector: 'manufacturer-audit-and-observation-table',
    templateUrl: './manufacturer-audit-and-observation-table-result.component.html',
    styleUrls: ['./manufacturer-audit-and-observation-table-result.component.css']
})
export class ManufacturerAuditAndObservationTableComponent implements OnInit {
    @Input() records: IAuditObservationDetail[] = [];
    displayedColumns: string[] = ['recordNumber', 'manufacturer', 'isRML', 'recordType', 'leadName', 'commodity', 'rating', 'date', 'status'];
    dataSource: MatTableDataSource<IAuditObservationDetail>;

    constructor() { }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.records);
        console.log("ManufacturerAuditAndObservationTableComponent: " + this.dataSource);
    }

    getRatingClass(rating: string): string {
        if (rating == RatingType.Qualified) {
            return 'rating-qualified';
        } else if (rating == RatingType.ConditionallyQualified) {
            return 'rating-conditionally-qualified';
        } else if (rating == RatingType.NotQualified) {
            return 'rating-not-qualified';
        } else if (rating == RatingType.NotAvailable) {
            return 'rating-not-available';
        }
    }
}  
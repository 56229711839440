// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-form {
  width: inherit;
}

.date-picker-container {
  padding-left: 10px;
}

.no-records-text {
  margin: 30px 0px;
  padding: 10px 20px;
  border: 1px solid black;
  width: 100%;
  box-sizing: border-box;
}

.button-container {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}

.table-loading-spinner {
  margin-top: 30px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/qa-management/manufacturer-audit-and-observation/manufacturer-audit-and-observation.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;EACvB,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".date-form {\n  width: inherit;\n}\n\n.date-picker-container {\n  padding-left: 10px;\n}\n\n.no-records-text {\n  margin: 30px 0px;\n  padding: 10px 20px;\n  border: 1px solid black;\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.button-container {\n  display: flex;\n  gap: 30px;\n  margin-top: 30px;\n}\n\n.table-loading-spinner {\n  margin-top: 30px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

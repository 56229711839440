import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { ILookupData } from "../../interfaces/administration/lookupData";
import { environment } from "projects/aqua3/src/environments/environment";
import { Router } from "@angular/router";
import { LookupDataService } from "../../services/administration/lookup-data-service";
import { IEditBUFormData } from "./forms/IEditBUFormData";
import { BusOpcosDataService } from "../../services/administration/bus-opcos-data-service";
import { MatTableDataSource } from "@angular/material/table";

const apiUrl = environment.apiUrl;

@Component({
  selector: "bus-opcos-component",
  templateUrl: "./bus-opcos.component.html",
  styleUrls: ["./bus-opcos.component.css"],
})
export class BUsAndOpcosComponent {
  title = "Business Units and Operating companies";
  selectedOpco: ILookupData = null;

  public operatingCompaniesColDef = ["name", "edit"];
  public businessUnitsColDef = ["name", "edit"];

  public rowOperatingCompaniesData!: ILookupData[];
  public rowBusinessUnitsData!: ILookupData[];
  public operatingCompaniesLoading: boolean = true;
  public businessUnitsLoading: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private lookupDataService: LookupDataService,
    private busOpcosDataService: BusOpcosDataService
  ) {}

  // Operating Companies
  ngOnInit() {
    this.rowOperatingCompaniesData = [];
    this.rowBusinessUnitsData = [];

    this.busOpcosDataService.getOpcos().subscribe((data) => {
      this.rowOperatingCompaniesData = data;
      this.operatingCompaniesLoading = false;
    });
  }

  // Business Units
  onOpcoRowSelected(lookupData: ILookupData) {
    const opcoId: number = lookupData.lookupId;

    this.businessUnitsLoading = true;
    this.busOpcosDataService.getBUsByOpcoId(opcoId).subscribe((data) => {
      this.rowBusinessUnitsData = data;
      this.businessUnitsLoading = false;
    });
  }

  editOpco(lookupData: ILookupData) {
    this.lookupDataService.updateOpcoData(lookupData);
    this.router.navigate([
      "administration/business-unit-opcos/edit-opco-data-form",
    ]);
  }

  editBU(bUnit: ILookupData) {
    let formData: IEditBUFormData = {
      bUnit: bUnit,
      parentOpco: this.selectedOpco,
      opcos: this.rowOperatingCompaniesData,
    };
    this.lookupDataService.updateBUData(formData);

    this.router.navigate([
      "administration/business-unit-opcos/edit-bu-data-form",
    ]);
  }

  addOpco() {
    this.router.navigate([
      "administration/business-unit-opcos/add-opco-data-form",
    ]);
  }

  addBU() {
    this.lookupDataService.addBUData(this.selectedOpco);
    this.router.navigate([
      "administration/business-unit-opcos/add-bu-data-form",
    ]);
  }

  highlightRow(opco: ILookupData) {
    this.selectedOpco = opco;
  }
}

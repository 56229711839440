import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NextPageService } from "@cvx/nextpage";
import { NextPageRoute } from "@cvx/nextpage/public_api";
import { HomeComponent } from "./home/home.component";
import { MsalGuard, MsalRedirectComponent } from "@azure/msal-angular";
import { FAQComponent } from "./help/faq/faq.component";
import { ContactComponent } from "./help/contact/contact.component";
import { HelpComponent } from "./help/help.component";
import { ToDoComponent } from "./shared/todo/todo.component";
import { AdministrationComponent } from "./administration/administration.component";
import { AlertsComponent } from "./administration/alerts/alerts.component";
import { ReportsFormsComponent } from "./reports-forms/reports-forms.component";
import { VendorInformationComponent } from "./vendor-information/vendor-information.component";
import { ApprovedVendorListComponent } from "./approved-vendor-list/approved-vendor-list.component";
import { UsersAndRolesComponent } from "./administration/users-roles/users-roles.component";
import { CountriesAndStatesComponent } from "./administration/countries-states/countries-states.component";
import { VendorsComponent } from "./administration/vendors/vendors.component";
import { CreateVendorComponent } from "./administration/vendors/create-vendor.component";
import { BUsAndOpcosComponent } from "./administration/bus-opcos/bus-opcos.component";
import { EditOpcoDataFormComponent } from "./administration/bus-opcos/forms/edit-opco-data.form.component";
import { EditVendorComponent } from "./administration/vendors/edit-vendor.component";
import { PreviewVendorComponent } from "./administration/vendors/preview-vendor.component";
import { EditBUDataFormComponent } from "./administration/bus-opcos/forms/edit-bu-data-form.component";
import { AddOpcoDataFormComponent } from "./administration/bus-opcos/forms/add-opco-data-form.component";
import { CreateCountryComponent } from "./administration/countries-states/create-country/create-country.component";
import { EditCountryComponent } from "./administration/countries-states/edit-country/edit-country.component";
import { PurchaserManagementComponent } from "./administration/purchaser-management/purchaser-management.component";
import { CreatePurchaserManagementComponent } from "./administration/purchaser-management/create-purchaser-management.component";
import { EditPurchaserManagementComponent } from "./administration/purchaser-management/edit-purchaser-management.component";
import { AddBUDataFormComponent } from "./administration/bus-opcos/forms/add-bu-data-form.component";
import { CreateStateComponent } from "./administration/countries-states/create-state/create-state.component";
import { EditStateComponent } from "./administration/countries-states/edit-state/edit-state.component";
import { EditUserRequestsFormComponent } from "./administration/administration-home-subcomponents/user-requests/edit-user-request-form.component";
import { CustomErrorComponent } from "./shared/error-page/custom-error-page.component";
import { PerformActionCommodityRequestFormComponent } from "./administration/administration-home-subcomponents/commodity-requests/edit-commodity-request/perform-action-commodity-request-form.component";
import { CommodityCodesComponent } from "./administration/commodity-codes/commodity-codes.component";
import { ProjectAndAvlComponent } from "./administration/project-and-avl/project-and-avl.component";
import { CreateProjectComponent } from "./approved-vendor-list/create-project.component";
import { AgenciesAndInspectorsComponent } from "./qa-management/agencies-and-inspectors/agencies-and-inspectors-component";
import { EditUserComponent } from "./administration/users-roles/edit-user/edit-user.component";
import { AddUserComponent } from "./administration/users-roles/add-user/add-user.component";
import { EditProjectComponent } from "./approved-vendor-list/edit-project.component";
import { PreviewProjectComponent } from "./approved-vendor-list/preview-project.component";
import { AddCommodityToVendorComponent } from "./vendor-information/add-commodity-to-vendor/add-commodity-to-vendor.component";
import { BuyersEngineersQaesComponent } from "./qa-management/buyers-engineers-qaes/buyers-engineers-qaes-component";
import { BuyersEngineersQaesCreateComponent } from "./qa-management/buyers-engineers-qaes/buyers-engineers-qaes-create-component";
import { BuyersEngineersQaesEditComponent } from "./qa-management/buyers-engineers-qaes/buyers-engineers-qaes-edit-component";
import { EditInspectorFormComponent } from "./qa-management/agencies-and-inspectors/inspectors/edit-inspector-form.component";
import { AddVendorComponent } from "./vendor-information/add-vendor/add-vendor.component";
import { CreateNewVendorComponent } from "./vendor-information/add-vendor/create-new-vendor.component";
import { VendorSearchComponent } from "./vendor-information/vendor-search/vendor-search.component";
import { AddInspectorFormComponent } from "./qa-management/agencies-and-inspectors/inspectors/add-inspector-form.component";
import { EditAgencyFormComponent } from "./qa-management/agencies-and-inspectors/agencies/edit-agency-form.component";
import { AddAgencyFormComponent } from "./qa-management/agencies-and-inspectors/agencies/add-agency-form.component";
import { VendorDetailsComponent } from "./vendor-information/vendor-search/vendor-details.component";
import { WorkRequestsComponent } from "./qa-management/work-requests/work-requests-component";
import { ManufacturerAuditAndObservationComponent } from "./qa-management/manufacturer-audit-and-observation/manufacturer-audit-and-observation.component";

export const routes: NextPageRoute[] = [
  {
    path: "home",
    title: "Home",
    redirectTo: "/",
    pathMatch: "full",
  },
  {
    // Needed for handling redirect after login
    path: "auth",
    component: MsalRedirectComponent,
  },
  {
    path: "qa-management",
    title: "QA Management",
    children: [
      {
        path: "",
        title: "QA Management",
        component: ToDoComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "work-requests",
        title: "Work Requests",
        children: [
          {
            path: "",
            component: WorkRequestsComponent,
            canActivate: [MsalGuard],
          },
        ]
      },
      {
        path: "agencies-and-inspectors",
        title: "Agencies and Inspectors",
        children: [
          {
            path: "",
            component: AgenciesAndInspectorsComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "edit-inspector/:id",
            component: EditInspectorFormComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "add-inspector",
            component: AddInspectorFormComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "edit-agency/:id",
            component: EditAgencyFormComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "add-agency",
            component: AddAgencyFormComponent,
            canActivate: [MsalGuard],
          },
        ],
      },
      {
        path: "buyers-engineers-qaes",
        title: "Buyers, Engineers, QAEs",
        children: [
          {
            path: "",
            component: BuyersEngineersQaesComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "create",
            component: BuyersEngineersQaesCreateComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "edit",
            component: BuyersEngineersQaesEditComponent,
            canActivate: [MsalGuard],
          },
        ],
      },
      {
        path: "manufacturer-audit-observation",
        title: "Manufacturer Audit and Observation",
        component: ManufacturerAuditAndObservationComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "inspector-evaluation",
        title: "Inspector Evaluation",
        component: ToDoComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "upload-inspection-report",
        title: "Upload Inspection Report",
        component: ToDoComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "upload-documents",
        title: "Upload Documents",
        component: ToDoComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
  {
    path: "approved-vendor-list",
    title: "Approved Vendor List (AVL)",
    children: [
      {
        path: "",
        component: ApprovedVendorListComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "create",
        component: CreateProjectComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "edit",
        component: EditProjectComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "preview",
        component: PreviewProjectComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
  {
    path: "vendor-information",
    title: "Vendor Information",
    canActivate: [MsalGuard],
    children: [
      {
        path: "",
        title: "Vendor Information",
        component: VendorInformationComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "performance-tracking",
        title: "Performance Tracking",
        component: ToDoComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "vendor-search",
        title: "Vendor Search",
        component: VendorSearchComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "add-vendor",
        title: "Add Vendor",
        component: AddVendorComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "vendor-details/:vendorId",
        title: "Vendor Details",
        component: VendorDetailsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "add-commodity-to-vendor",
        title: "Add Commodity to Vendor",
        component: AddCommodityToVendorComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "create-vendor",
        component: CreateNewVendorComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
  {
    path: "reports-forms",
    title: "Reports & Forms",
    component: ReportsFormsComponent,
  },
  {
    path: "administration",
    title: "Administration",
    children: [
      {
        path: "",
        title: "Administration",
        component: AdministrationComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "adminHome",
        title: "Administration Home",
        children: [
          {
            path: "",
            component: AdministrationComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "user-requests/edit/:id",
            component: EditUserRequestsFormComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "commodity-requests/performAction",
            component: PerformActionCommodityRequestFormComponent,
            canActivate: [MsalGuard],
          },
        ],
      },
      {
        path: "alerts",
        title: "Alerts",
        component: AlertsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "projects-approved-vendor-lists",
        title: "Projects & Approved Vendor Lists",
        component: ProjectAndAvlComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "business-unit-opcos",
        title: "Business Unit & OPCOs",
        children: [
          {
            path: "",
            component: BUsAndOpcosComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "edit-opco-data-form",
            component: EditOpcoDataFormComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "edit-bu-data-form",
            component: EditBUDataFormComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "add-opco-data-form",
            component: AddOpcoDataFormComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "add-bu-data-form",
            component: AddBUDataFormComponent,
            canActivate: [MsalGuard],
          },
        ],
      },
      {
        path: "countries-states",
        title: "Countries & States",
        children: [
          {
            path: "",
            component: CountriesAndStatesComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "create-country",
            component: CreateCountryComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "edit-country",
            component: EditCountryComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "create-state",
            component: CreateStateComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "edit-state",
            component: EditStateComponent,
            canActivate: [MsalGuard],
          },
        ],
      },
      {
        path: "commodity-codes",
        title: "Commodity Codes",
        component: CommodityCodesComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "user-roles",
        title: "User and Roles",
        children: [
          {
            path: "",
            component: UsersAndRolesComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "edit-user",
            component: EditUserComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "add-user",
            component: AddUserComponent,
            canActivate: [MsalGuard],
          },
        ],
      },
      {
        path: "purchaser-management",
        title: "Purchaser Management",
        children: [
          {
            path: "",
            component: PurchaserManagementComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "create",
            component: CreatePurchaserManagementComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "edit/:id",
            component: EditPurchaserManagementComponent,
            canActivate: [MsalGuard],
          },
        ],
      },
      {
        path: "vendors",
        title: "Vendors",
        children: [
          {
            path: "",
            component: VendorsComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "preview",
            component: PreviewVendorComponent,
          },
          {
            path: "create",
            component: CreateVendorComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "preview/:id",
            component: PreviewVendorComponent,
            canActivate: [MsalGuard],
          },
          {
            path: "edit/:id",
            component: EditVendorComponent,
            canActivate: [MsalGuard],
          },
        ],
      },
      {
        path: "msa",
        title: "MSA",
        component: ToDoComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
  {
    path: "help",
    title: "Help",
    canActivate: [MsalGuard],
    children: [
      {
        path: "",
        title: "Help",
        component: HelpComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "contact-us",
        title: "Contact",
        component: ContactComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "faq",
        title: "FAQ",
        component: FAQComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
  {
    path: "",
    title: "Main",
    children: [
      {
        path: "",
        component: HomeComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
  { path: "error", title: "Error", component: CustomErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private NP: NextPageService) {
    this.NP.options.navigationRoutes = routes;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ILoggedUserData } from '../../interfaces/common/loggedUserData';

@Injectable({
  providedIn: 'root'
})
export class LoggedUserService {
  private loggedUserDataSubject = new BehaviorSubject<ILoggedUserData | null>(null);
  public loggedUserData$: Observable<ILoggedUserData | null> = this.loggedUserDataSubject.asObservable();

  constructor() {}

  setLoggedUserData(data: ILoggedUserData): void {
    this.loggedUserDataSubject.next(data);
  }

  getLoggedUserData(): Observable<ILoggedUserData | null> {
    return this.loggedUserData$;
  }
}
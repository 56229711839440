import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'commodity-request-button-component',
  templateUrl: './commodity-request-button.component.html'
})
export class CommodityRequestButtonComponent implements ICellRendererAngularComp {
  public value!: string;
  agInit(params: ICellRendererParams): void {
    this.value = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.value;
    return true;
  }

    solveRequest() {
    console.log('Solve Commodity Request: ' + this.value);
  }
}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CountriesAndStatesService } from "../../../services/countries-states.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ILookupData } from "../../../interfaces/administration/lookupData";

@Component({
  selector: "app-edit-country",
  templateUrl: "./edit-country.component.html",
  styles: [
    `
      fieldset {
        border: none !important;
      }
    `,
  ],
})
export class EditCountryComponent implements OnInit {
  public countryData: ILookupData;

  constructor(
    private router: Router,
    private countriesAndStatesService: CountriesAndStatesService,
    private fb: FormBuilder,
  ) { }

  country: ILookupData;
  editForm: FormGroup;
  validationMessages = [];

  ngOnInit() {
    this.countriesAndStatesService.currentCountryData$.subscribe((data) => {
      if (data) {
        console.log(data);
        this.countryData = data;
      }
    });
    this.countriesAndStatesService.currentCountryData$.subscribe(data => {
      this.country = data;
      this.editForm = this.fb.group({
        countryName: [this.country.name, Validators.required],
        isActive: [this.country.isActive],
      });
    });
  }

  submitForm(): void {
    let messagesValidationsList: string[] = [];
    let formCountryName: string = this.editForm.get('countryName').value;
    let formCountryIsActive: boolean = this.editForm.get('isActive').value;

    if (formCountryName === "")
      messagesValidationsList.push('Please enter the name of a Country.');

    this.validationMessages = messagesValidationsList;

    if (this.editForm.valid && this.validationMessages.length === 0) {
      this.country.name = formCountryName;
      this.country.isActive = formCountryIsActive;

      this.countriesAndStatesService.updateCountry(this.country.lookupId.toString(), this.country).subscribe((data) => {
        if (data) {
          this.backToCountriesAndStates();
        }
      });
    }
  }

  cancel(): void {
    this.backToCountriesAndStates();
  }

  private backToCountriesAndStates() {
    this.router.navigate(["administration/countries-states"]);
  }
}

import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { IUserData } from '../../../interfaces/administration/userData';

@Component({
  selector: 'settings-component',
  templateUrl: './settings-component.html'
})
export class SettingsComponent {
  links: any[] = [];
  userRequestsTable: any[];

  constructor(private http: HttpClient) { }

  private gridApi!: GridApi<IUserData>;

  public colDefs: ColDef[] = [
    {
      field: "EmailNotification",
      suppressHeaderMenuButton: true,
      flex: 2
    },
    {
      field: "SystemAdministrator",
      headerName: "System Administrator(s)",
      suppressHeaderMenuButton: true,
      flex: 2
    },
    {
      field: "Email",
      suppressHeaderMenuButton: true,
      flex: 2
    },
  ];

  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 120,
    filter: true,
    floatingFilter: true,
  };

  public rowData!: any[];
  public themeClass: string =
    "ag-theme-material";

  onFilterTextBoxChanged() {
    this.gridApi.setGridOption(
      "quickFilterText",
      (document.getElementById("email-filter-text-box") as HTMLInputElement).value,
    );
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;

    this.http
      .get<any[]>('assets/administration/emailNotifications.json')
      .subscribe((data) => (this.rowData = data));
  }

  buttonClicked() {
    console.log('Button clicked: ');
  }

}

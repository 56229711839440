import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { VendorCommodityRequest } from "../../interfaces/vendor-information/vendorCommodityRequest";

const apiUrl = environment.apiUrl + '/vendorCommodity';

@Injectable({
    providedIn: "root",
})
export class VendorCommodityDataService {
    constructor(private http: HttpClient) { }

    public getManufacturerCommodityCountByCommodityId(manufacturerId: string, commodityId: string): Observable<number> {
        return this.http.get<number>(`${apiUrl}/manufacturerId/${manufacturerId}/commodityId/${commodityId}`);
    }

    public getSupplierCommodityCountByCommodityId(supplierId: string, commodityId: string): Observable<number> {
        return this.http.get<number>(`${apiUrl}/supplierId/${supplierId}/commodityId/${commodityId}`);
    }

    public insertVendorCommodityRequest(vendorCommodityRequest: VendorCommodityRequest): Observable<VendorCommodityRequest[]> {
        return this.http.post<VendorCommodityRequest[]>(`${apiUrl}/insertRequest`, vendorCommodityRequest);
    }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-table {
  width: 100%;
}

th.mat-header-cell,
td.mat-cell {
  padding: 8px 16px;
}

.manufacturer-aud-obs-table {
  margin: 30px 0px;
}

.rating-qualified {
  background-color: rgb(154, 205, 50);
}

.rating-conditionally-qualified {
  background-color: rgb(240, 230, 140);
}

.rating-not-qualified {
  background-color: rgb(255, 69, 0);
}

.rating-not-available {
  background-color: rgb(192, 192, 192);
}
`, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/qa-management/manufacturer-audit-and-observation/manufacturer-audit-and-observation-table-result/manufacturer-audit-and-observation-table-result.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;;EAEE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".mat-table {\n  width: 100%;\n}\n\nth.mat-header-cell,\ntd.mat-cell {\n  padding: 8px 16px;\n}\n\n.manufacturer-aud-obs-table {\n  margin: 30px 0px;\n}\n\n.rating-qualified {\n  background-color: rgb(154, 205, 50);\n}\n\n.rating-conditionally-qualified {\n  background-color: rgb(240, 230, 140);\n}\n\n.rating-not-qualified {\n  background-color: rgb(255, 69, 0);\n}\n\n.rating-not-available {\n  background-color: rgb(192, 192, 192);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

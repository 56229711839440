export class ICommodity {
    commodityId: number;
    childrenCount: number;
    description: string;
    commodityDescriptionPath: string;
    commodityCodeParentId: number;
    commodityLevel: number;
    commodityCodeLevel1: number;
    commodityCodeLevel2: number;
    commodityCodeLevel3: number;
    commodityCodeLevel4: number;
    commodityCodeTrimmed: string;
    isActive: boolean;
    isApproved: boolean;
    subjectMatterExpertUserId: number;
    approvedByUserId: number | null;
    approvedDate: string;
    approvedComments: string | null;
    approverFirstName: string | null;
    approverLastName: string | null;
    approverEmail: string | null;
    approverCai: string | null;
    smefirstName: string;
    smelastName: string;
    smeemail: string;
    smecai: string;
    children: ICommodity[];
    shouldExpand: boolean = false;
    searched: boolean = false;
    isLoading: boolean = false;
    isExpandable: boolean = false;
    commodityPath: string;
}

export class CommoditySorter {
    static sortCommodities(commodities: ICommodity[]): ICommodity[] {
        return commodities.sort((a, b) => {
            if (a.commodityCodeLevel1 < b.commodityCodeLevel1) return -1;
            if (a.commodityCodeLevel1 > b.commodityCodeLevel1) return 1;

            if (a.commodityCodeLevel2 < b.commodityCodeLevel2) return -1;
            if (a.commodityCodeLevel2 > b.commodityCodeLevel2) return 1;

            if (a.commodityCodeLevel3 < b.commodityCodeLevel3) return -1;
            if (a.commodityCodeLevel3 > b.commodityCodeLevel3) return 1;

            if (a.commodityCodeLevel4 < b.commodityCodeLevel4) return -1;
            if (a.commodityCodeLevel4 > b.commodityCodeLevel4) return 1;

            return 0;
        });
    }
}

export class CommodityLevel {
    static returnCommodityCodeLevel(commodity: ICommodity, level: number): number {
        switch (level) {
            case 1:
                return commodity.commodityCodeLevel1;
            case 2:
                return commodity.commodityCodeLevel2;
            case 3:
                return commodity.commodityCodeLevel3;
            case 4:
                return commodity.commodityCodeLevel4;
            default:
                return 0;
        }
    }

    static returnTrimmedCommodityLevel(commodity: ICommodity): string {
        var result = commodity.commodityCodeLevel1.toString();
        for (let i = 2; i <= commodity.commodityLevel; i++) {
            result += "." + this.returnCommodityCodeLevel(commodity, i).toString();
        }
        return result;
    }
}

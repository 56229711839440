import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NavigationCachingService {
  private loadCachedAgenciesSubject = new Subject<void>();
  loadCachedAgencies$ = this.loadCachedAgenciesSubject.asObservable();

  private loadCachedInspectorsSubject = new Subject<void>();
  loadCachedInspectors$ = this.loadCachedInspectorsSubject.asObservable();

  public triggerLoadCachedAgencies() {
    this.loadCachedAgenciesSubject.next();
  }

  public triggerLoadCachedInspectors() {
    this.loadCachedInspectorsSubject.next();
  }
}
